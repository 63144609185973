import { useNavigate, useSearchParams } from 'react-router-dom';
import jar from '../helpers/cookies';
import { useEffect } from 'react';
import React from 'react';

export default function AuthCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      return navigate('/login?error=invalid_token');
    }

    jar.set('token', token, {
      maxAge: 21600,
      path: '/'
    });

    return navigate('/onboarding');
  }, []);

  return <>{token}</>;
}
