import React, { useState, useEffect } from 'react';
import AppBarDrawer from '../../components/layout/AppBarDrawer';
import { useLocation, useNavigate } from 'react-router-dom';
// import moment from 'moment';
import help_circle from '../../images/help-circle.svg';
import github from '../../images/Github.svg';
import linkedin from '../../images/LinkedIn.png';
import behance from '../../images/Behance.png';
import dribble from '../../images/Dribbble.png';
import user from '../../images/user.svg';
import del from '../../images/delete.svg';
import Languages from '../../components/lists/Languages';
import { Tooltip } from 'react-tooltip';
// import edit from '../../images/edit.svg';
// import link from '../../images/link.svg';
// import spinner from '../../images/Spinner.svg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import jar from '../../helpers/cookies';
import { URL } from '../../config/URL';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useUser } from '../../context/UserContext';
import DatePicker from 'react-multi-date-picker';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const toastConfigVerify = {
  position: 'top-center',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  className: 'tosatErrorMsg',
  enableHtml: true
};

const toastConfigErrorMsg = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  className: 'tosatErrorMsg'
};

yup.addMethod(yup.string, 'isEmptyString', function () {
  return this.transform((value) => (value === '' ? undefined : value));
});

const validationSchema = yup.object({
  years_of_experience: yup
    .number()
    .min(1)
    .integer('It should be a positive number')
    .required('Years of experience is required'),
  veterans_status: yup.string().required('Veterans status is required'),
  disability_status: yup.bool().required('Disability status is required'),
  criminal_status: yup.bool().required('Criminal status is required'),
  willing_to_relocate: yup
    .bool()
    .required('Willing to relocate status is required'),
  ethnicity: yup.string().required('Ethnicity is required'),
  notice_period_in_days: yup.number().required('Notice period is required'),
  require_visa_sponsorship: yup
    .bool()
    .required('Need visa sponsorship is required'),
  has_driver_license: yup.bool().required('License status required'),
  preferred_way_of_working: yup
    .string()
    .required('Preferred way of working is required'),
  highest_degree: yup.string().required('Highest is Required'),
  interview_date: yup
    .array()
    .of(yup.string())
    .min(5, 'Select minimum 5 dates')
    .max(5, 'Select maximum 5 dates')
    .required('Select Dates when you are available for Interview'),
  autorized_work_country: yup
    .array()
    .of(
      yup.object({
        label: yup
          .string()
          .required('Countries you are autorized to work are required'),
        value: yup
          .string()
          .required('Countries you are autorized to work are required')
      })
    )
    .required('Countries you are autorized to work are required'),
  exclude_company: yup.string().optional(),
  have_own_vehicle: yup.bool().required('Status is required'),
  has_security_clearance: yup.bool().required('Status is required'),
  expected_salary: yup
    .object({
      value: yup.number().required('Expected salary is required'),
      currency: yup.string().required('Currency is required')
    })
    .required('Expected salary is required'),
  current_salary: yup
    .object({
      value: yup.number().required('Current salary is required'),
      currency: yup.string().required('Currency is required')
    })
    .required('Current salary is required'),
  // full_name: yup.string().required('Full name is required'),
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  gender: yup.string().required('Gender is required'),
  email: yup.string().required('Email is required').email('Invalid email'),
  country_code: yup.string().required('Country Code is required'),
  phone: yup.string().required('Phone number is required'),
  address: yup
    .object({
      street: yup.string().required('Street Address is required'),
      // town: yup.string().required('Twon Address is required'),
      city: yup.string().required('City is required'),
      state: yup.string().required('Satate is required'),
      postal_code: yup.string().required('Postal code is required'),
      country: yup.string().required('Country is required')
    })
    .required('Address is required'),
  github_url: yup.string().optional(),
  linkedin_url: yup.string().optional(),
  behance_url: yup.string().optional(),
  dribbble_url: yup.string().optional(),
  portfolio_url: yup.string().optional(),
  summary: yup.string().required('Summary is required'),
  work: yup
    .array()
    .of(
      yup.object({
        company_name: yup.string().isEmptyString().optional(),
        job_title: yup.string().isEmptyString().optional(),
        location: yup.string().required('Work Location is required'),
        experience_type: yup.string().required('Role type is required'),
        start_date: yup
          .string()
          .max(new Date(), 'Cannot use future date')
          .required('Work start date is required'),
        end_date: yup
          .string()
          .optional()
          // .min(
          //   yup.ref('start_date'),
          //   'End date must be equal or later than start date'
          // )
          .nullable()
          .default(undefined),
        currently_working: yup.bool(),
        description: yup.string().required('Description is required')
      })
    )
    .min(1, 'Add at least 1 work experience')
    .required('Work details are required'),
  education: yup
    .array()
    .of(
      yup.object({
        degree_level: yup.string().required('Degree level is required'),
        degree_title: yup.string().required('Degree title is required'),
        institution_name: yup.string().required('Institution name is required'),
        institution_type: yup.string().required('Institution type is required'),
        institution_city: yup.string().required('Institution city is required'),
        institution_country: yup
          .string()
          .required('Institution country is required'),
        field_of_study: yup.string().required('Field of study is required'),
        grade: yup.string().required('Grade is required'),
        start_date: yup
          .string()
          .max(new Date(), 'Cannot use future date')
          .required('Start date is required'),
        end_date: yup
          .string()
          .optional()
          // .min(
          //   yup.ref('start_date'),
          //   'End date must be equal or later than start date'
          // )
          .nullable()
          .default(undefined),
        currently_studying: yup.bool(),
        description: yup.string().required('Description is required')
      })
    )
    .min(1, 'Add at least 1 education detail')
    .required('Education details are required'),
  skills: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required('Skill name is required'),
        years_of_experience: yup
          .number('Experience must be a number')
          .min(1, 'Experience must be at least 1')
          .integer('Experience must be an integer')
          .required('Experience is required')
      })
    )
    .min(3, 'Add at least 3 skill')
    .required('Skills are required'),
  languages: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required('Language name is required'),
        level: yup.string().required('Select level of proficiency')
      })
    )
    .min(1, 'Add at least 1 skill')
    .required('Skills are required'),
  achievements: yup
    .array()
    .of(
      yup.object({
        award_name: yup.string().required('Award name is required'),
        // issued_by: yup.string().required('Issuer name is required'),
        achievement_date: yup.string().required('Date is required'),
        description: yup.string().required('Description is required')
      })
    )
    .optional('Achievement details are required'),
  certifications: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required('Certification name is required'),
        // issued_by: yup.string().required('Certificate issuer name is required'),
        certification_date: yup
          .string()
          .required('Certification start date is required'),
        // start_date: yup
        //   .string()
        //   .required('Certification start date is required'),
        // end_date: yup.string().optional(),
        link: yup.string().optional(),
        certificate_id: yup.string().optional(),
        description: yup
          .string()
          .required('Certification description is required')
      })
    )
    .optional('Certification details are required'),
  cover_letter: yup.string().optional()
  // message_to_hr: yup.string().required('is required'),
});

export default function EditResume() {
  // const { userProfile } = useUser();
  const { countriesAll, loading } = useUser();
  const bearerToken = jar.get('token');
  const UpdateResumeAPI = `${URL}/resume`;
  const location = useLocation();
  const navigate = useNavigate();
  const resumeData = location.state ? location.state.resumeData : null;
  console.log('resumeData---:', resumeData);
  // const [coverletter, setCoverLetter] = useState();
  // const coverLetterThreshold = userProfile?.activation?.cover_letter_threshold;

  const [applyExperience, setApplyExperience] = useState('');
  // const [originalSkills, setOriginalSkills] = useState([]);

  // useEffect(() => {
  //   if (resumeData?.skills) {
  //     setOriginalSkills(resumeData.skills);
  //   }
  // }, [resumeData]);

  const handleApplyExperience = () => {
    skillFields.forEach((field, index) => {
      setValue(`skills.${index}.years_of_experience`, applyExperience);
      // if (field.years_of_experience === '') {
      // }
    });
  };

  // const handleUndo = () => {
  //   originalSkills.forEach((index) => {
  //     setValue(`skills.${index}.years_of_experience`, '');
  //   });
  // };

  // Date Picker
  const [dates, setDates] = useState([]);
  const today = new Date();
  const handleDateChange = (ranges) => {
    // Validate and filter out past dates
    const validDates = ranges.filter((range) => range.endDate >= today);
    setDates(validDates);
  };

  // Convert Missing values to undefined
  const undefinedKeywords = [
    null,
    '',
    ' ',
    NaN,
    'N/A',
    'NaN',
    'nan',
    'Nan',
    'NAN',
    'naN',
    'not provided',
    'Not provided',
    'Not Provided'
  ];

  const convertToUndefined = (value) => {
    return undefinedKeywords.includes(value) ? undefined : value;
  };

  // User Confirmation
  const sections = [
    { id: 'other-info', name: 'Other Information' },
    { id: 'personal-info', name: 'Personal Information' },
    { id: 'work-history', name: 'Work Experience' },
    { id: 'education', name: 'Education' },
    { id: 'skills', name: 'Skills' },
    { id: 'languages', name: 'Languages' },
    { id: 'achievements', name: 'Achievements' },
    { id: 'certifications', name: 'Certifications' },
    { id: 'cover-letter', name: 'Cover Letter' }
  ];

  const [confirmations, setConfirmations] = useState(
    sections.reduce((acc, section) => {
      acc[section.id] = false;
      return acc;
    }, {})
  );
  const [highlight, setHighlight] = useState(
    sections.reduce((acc, section) => {
      acc[section.id] = undefined;
      return acc;
    }, {})
  );

  const handleConfirmation = (sectionId) => {
    setConfirmations((prevConfirmations) => ({
      ...prevConfirmations,
      [sectionId]: !prevConfirmations[sectionId]
    }));

    setHighlight((prevHighlight) => ({
      ...prevHighlight,
      [sectionId]: !prevHighlight[sectionId]
    }));
  };

  // Validation Begin
  const {
    // register,
    control,
    handleSubmit,
    formState,
    setValue,
    // watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      years_of_experience:
        convertToUndefined(resumeData?.years_of_experience) === undefined
          ? undefined
          : resumeData.years_of_experience,
      veterans_status:
        convertToUndefined(resumeData?.veterans_status) === undefined
          ? undefined
          : resumeData.veterans_status,
      disability_status:
        convertToUndefined(resumeData?.disability_status) === undefined
          ? undefined
          : resumeData.disability_status,
      criminal_status:
        convertToUndefined(resumeData?.criminal_status) === undefined
          ? undefined
          : resumeData.criminal_status,
      willing_to_relocate:
        convertToUndefined(resumeData?.willing_to_relocate) === undefined
          ? undefined
          : resumeData.willing_to_relocate,
      ethnicity:
        convertToUndefined(resumeData?.ethnicity) === undefined
          ? undefined
          : resumeData.ethnicity,
      expected_salary:
        convertToUndefined(resumeData?.expected_salary) === undefined
          ? undefined
          : resumeData.expected_salary,
      current_salary:
        convertToUndefined(resumeData?.current_salary) === undefined
          ? undefined
          : resumeData.current_salary,
      has_driver_license:
        convertToUndefined(resumeData?.has_driver_license) === undefined
          ? undefined
          : resumeData.has_driver_license,
      have_own_vehicle:
        convertToUndefined(resumeData?.have_own_vehicle) === undefined
          ? undefined
          : resumeData.have_own_vehicle,
      highest_degree:
        convertToUndefined(resumeData?.highest_degree) === undefined
          ? undefined
          : resumeData.highest_degree,
      interview_date:
        convertToUndefined(resumeData?.interview_date) === undefined
          ? undefined
          : resumeData.interview_date,
      preferred_way_of_working:
        convertToUndefined(resumeData?.preferred_way_of_working) === undefined
          ? undefined
          : resumeData.preferred_way_of_working,
      notice_period_in_days:
        convertToUndefined(resumeData?.notice_period_in_days) === undefined
          ? undefined
          : resumeData.notice_period_in_days,
      autorized_work_country:
        convertToUndefined(resumeData?.autorized_work_country) === undefined
          ? undefined
          : resumeData.autorized_work_country,
      exclude_company:
        convertToUndefined(resumeData?.exclude_company) === undefined
          ? undefined
          : resumeData.exclude_company,
      require_visa_sponsorship:
        convertToUndefined(resumeData?.require_visa_sponsorship) === undefined
          ? undefined
          : resumeData.require_visa_sponsorship,
      has_security_clearance:
        convertToUndefined(resumeData?.has_security_clearance) === undefined
          ? undefined
          : resumeData?.has_security_clearance,
      first_name:
        convertToUndefined(resumeData?.first_name) === undefined
          ? undefined
          : resumeData.first_name,
      last_name:
        convertToUndefined(resumeData?.last_name) === undefined
          ? undefined
          : resumeData.last_name,
      gender:
        convertToUndefined(resumeData?.gender) === undefined
          ? undefined
          : resumeData.gender,
      email:
        convertToUndefined(resumeData?.email) === undefined
          ? undefined
          : resumeData.email,
      country_code:
        convertToUndefined(resumeData?.country_code) === undefined
          ? undefined
          : resumeData.country_code,
      phone:
        convertToUndefined(resumeData?.phone) === undefined
          ? undefined
          : resumeData.phone,
      address: {
        ...resumeData.address,
        street:
          convertToUndefined(resumeData?.address?.street) === undefined
            ? undefined
            : resumeData?.address?.street,
        town:
          convertToUndefined(resumeData?.address?.town) === undefined
            ? undefined
            : resumeData?.address?.town,
        city:
          convertToUndefined(resumeData?.address?.city) === undefined
            ? undefined
            : resumeData?.address?.city,
        state:
          convertToUndefined(resumeData?.address?.state) === undefined
            ? undefined
            : resumeData?.address?.state,
        postal_code:
          convertToUndefined(resumeData?.address?.postal_code) === undefined
            ? undefined
            : resumeData?.address?.postal_code,
        country:
          convertToUndefined(resumeData?.address?.country) === undefined
            ? undefined
            : resumeData?.address?.country
      },
      github_url:
        convertToUndefined(resumeData?.github_url) === undefined
          ? undefined
          : resumeData?.github_url,
      linkedin_url:
        convertToUndefined(resumeData?.linkedin_url) === undefined
          ? undefined
          : resumeData?.linkedin_url,
      behance_url:
        convertToUndefined(resumeData?.behance_url) === undefined
          ? undefined
          : resumeData?.behance_url,
      dribbble_url:
        convertToUndefined(resumeData?.dribbble_url) === undefined
          ? undefined
          : resumeData?.dribbble_url,
      portfolio_url:
        convertToUndefined(resumeData?.portfolio_url) === undefined
          ? undefined
          : resumeData?.portfolio_url,
      summary:
        convertToUndefined(resumeData?.summary) === undefined
          ? undefined
          : resumeData?.summary,
      work:
        Array.isArray(resumeData?.work) &&
        resumeData?.work?.map((workItem) => ({
          ...workItem,
          company_name:
            convertToUndefined(workItem.company_name) === undefined
              ? undefined
              : workItem.company_name,
          job_title:
            convertToUndefined(workItem.job_title) === undefined
              ? undefined
              : workItem.job_title,
          location:
            convertToUndefined(workItem.location) === undefined
              ? undefined
              : workItem.location,
          experience_type:
            convertToUndefined(workItem.experience_type) === undefined
              ? undefined
              : workItem.experience_type,
          start_date:
            convertToUndefined(workItem.start_date) === undefined
              ? undefined
              : workItem.start_date,
          end_date:
            convertToUndefined(workItem.end_date) === undefined
              ? undefined
              : workItem.end_date,
          currently_working:
            convertToUndefined(workItem.currently_working) === undefined
              ? undefined
              : workItem.currently_working,
          description:
            convertToUndefined(workItem.description) === undefined
              ? undefined
              : workItem.description
        })),
      education:
        Array.isArray(resumeData?.education) &&
        resumeData?.education?.map((educationItem) => ({
          ...educationItem,
          degree_level:
            convertToUndefined(educationItem.degree_level) === undefined
              ? undefined
              : educationItem.degree_level,
          degree_title:
            convertToUndefined(educationItem.degree_title) === undefined
              ? undefined
              : educationItem.degree_title,
          field_of_study:
            convertToUndefined(educationItem.field_of_study) === undefined
              ? undefined
              : educationItem.field_of_study,
          institution_name:
            convertToUndefined(educationItem.institution_name) === undefined
              ? undefined
              : educationItem.institution_name,
          institution_type:
            convertToUndefined(educationItem.institution_type) === undefined
              ? undefined
              : educationItem.institution_type,
          institution_city:
            convertToUndefined(educationItem.institution_city) === undefined
              ? undefined
              : educationItem.institution_city,
          institution_country:
            convertToUndefined(educationItem.institution_country) === undefined
              ? undefined
              : educationItem.institution_country,
          grade:
            convertToUndefined(educationItem.grade) === undefined
              ? undefined
              : educationItem.grade,
          start_date:
            convertToUndefined(educationItem.start_date) === undefined
              ? undefined
              : educationItem.start_date,
          end_date:
            convertToUndefined(educationItem.end_date) === undefined
              ? undefined
              : educationItem.end_date,
          currently_studying:
            convertToUndefined(educationItem.currently_studying) === undefined
              ? undefined
              : educationItem.currently_studying,
          description:
            convertToUndefined(educationItem.description) === undefined
              ? undefined
              : educationItem.description
        })),
      skills:
        Array.isArray(resumeData?.skills) &&
        resumeData?.skills?.map((skillsItem) => ({
          ...skillsItem,
          name:
            convertToUndefined(skillsItem.name) === undefined
              ? undefined
              : skillsItem.name,
          years_of_experience:
            convertToUndefined(skillsItem.years_of_experience) === undefined
              ? undefined
              : skillsItem.years_of_experience
        })),
      languages:
        Array.isArray(resumeData?.languages) &&
        resumeData?.languages?.map((languageItem) => ({
          ...languageItem,
          name:
            convertToUndefined(languageItem.name) === undefined
              ? undefined
              : languageItem.name,
          level:
            convertToUndefined(languageItem.level) === undefined
              ? undefined
              : languageItem.level
        })),
      achievements:
        Array.isArray(resumeData?.achievements) &&
        resumeData?.achievements?.map((achievementItem) => ({
          ...achievementItem,
          award_name:
            convertToUndefined(achievementItem.award_name) === undefined
              ? undefined
              : achievementItem.award_name,
          // issued_by:
          //   convertToUndefined(achievementItem.issued_by) === undefined
          //     ? undefined
          //     : achievementItem.issued_by,
          achievement_date:
            convertToUndefined(achievementItem.achievement_date) === undefined
              ? undefined
              : achievementItem.achievement_date,
          description:
            convertToUndefined(achievementItem.description) === undefined
              ? undefined
              : achievementItem.description
        })),
      certifications:
        Array.isArray(resumeData?.certifications) &&
        resumeData?.certifications?.map((certificationItem) => ({
          ...certificationItem,
          name:
            convertToUndefined(certificationItem.name) === undefined
              ? undefined
              : certificationItem.name,
          // issued_by:
          //   convertToUndefined(certificationItem.issued_by) === undefined
          //     ? undefined
          //     : certificationItem.issued_by,
          certification_date:
            convertToUndefined(certificationItem.certification_date) ===
            undefined
              ? undefined
              : certificationItem.certification_date,
          // end_date:
          //   convertToUndefined(certificationItem.end_date) === undefined
          //     ? undefined
          //     : certificationItem.end_date,
          link:
            convertToUndefined(certificationItem.link) === undefined
              ? undefined
              : certificationItem.link,
          certificate_id:
            convertToUndefined(certificationItem.certificate_id) === undefined
              ? undefined
              : certificationItem.certificate_id,
          description:
            convertToUndefined(certificationItem.description) === undefined
              ? undefined
              : certificationItem.description
        })),
      cover_letter:
        convertToUndefined(resumeData?.cover_letter) === undefined
          ? undefined
          : resumeData?.cover_letter
    }
  });

  const { isDirty } = formState;
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        const message =
          'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  // Skills
  const {
    fields: skillFields,
    append: appendSkill,
    remove: removeSkill
  } = useFieldArray({
    name: 'skills',
    control,
    rules: {
      required: 'Please add at least 1 skill'
    }
  });

  const addSkill = () => {
    appendSkill({
      name: '',
      years_of_experience: applyExperience
    });
  };

  const deleteSkill = (index) => {
    removeSkill(index);
  };

  // Language
  const {
    fields: languageFields,
    append: appendLanguage,
    remove: removeLanguage
  } = useFieldArray({
    name: 'languages',
    control,
    rules: {
      required: 'Please add at least 1 Language'
    }
  });

  const addLanguage = () => {
    appendLanguage({
      name: '',
      level: 0
    });
  };

  const deleteLanguage = (index) => {
    removeLanguage(index);
  };

  // Work Experience
  const {
    fields: workFields,
    append: appendWork,
    remove: removeWork
  } = useFieldArray({
    name: 'work',
    control,
    rules: {
      required: 'Please add at least 1 work experience'
    }
  });
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);

  const addWork = () => {
    appendWork({
      company_name: '',
      job_title: '',
      location: '',
      experience_type: '',
      start_date: '',
      end_date: '',
      currently_working: false,
      description: ''
    });
  };

  const removeWorkItem = (index) => {
    removeWork(index);
  };

  // Education
  const {
    fields: eduFields,
    append: appendEdu,
    remove: removeEdu
  } = useFieldArray({
    name: 'education',
    control,
    rules: {
      required: 'Please add at least 1 education detail'
    }
  });

  const [isCurrentlyStudying, setIsCurrentlyStudying] = useState(false);

  const addEducation = () => {
    appendEdu({
      degree_level: '',
      degree_title: '',
      institution_name: '',
      institution_type: '',
      institution_city: '',
      institution_country: '',
      field_of_study: '',
      grade: '',
      start_date: '',
      end_date: '',
      currently_studying: false,
      description: ''
    });
  };

  const removeEducationItem = (index) => {
    removeEdu(index);
  };

  // Achievements
  const {
    fields: achievementFields,
    append: appendAchievement,
    remove: removeAchievementItem
  } = useFieldArray({
    name: 'achievements',
    control,
    rules: {
      required: 'Please add at least 1 achievement'
    }
  });

  const addAchievement = () => {
    appendAchievement({
      award_name: '',
      // issued_by: '',
      achievement_date: '',
      description: ''
    });
  };

  const removeAchievement = (index) => {
    removeAchievementItem(index);
  };

  // Certifications
  const {
    fields: certificationFields,
    append: appendCertification,
    remove: removeCertification
  } = useFieldArray({
    name: 'certifications',
    control,
    rules: {
      required: 'Please add at least 1 certification'
    }
  });

  const addCertificate = () => {
    appendCertification({
      name: '',
      issued_by: '',
      start_date: '',
      end_date: '',
      link: '',
      certificate_id: '',
      description: ''
    });
  };

  const deleteCertificate = (index) => {
    removeCertification(index);
  };

  const onSubmit = (data) => {
    setHasUnsavedChanges(false);
    if (Object.values(confirmations).every((confirmed) => confirmed)) {
      axios
        .post(`${UpdateResumeAPI}/${resumeData._id}`, data, {
          headers: {
            Authorization: `Bearer ${bearerToken}`
          }
        })
        .then((response) => {
          console.log('Resume updated successfully----:', data);
          // console.log('Resume updated successfully:', response.data);
          toast.success(response.data.msg, toastConfig);
          navigate('/update-resume');
        })
        .catch((error) => {
          console.error('Error updating resume data:', error);

          if (error.response && error.response.status === 422) {
            const validationErrors = error.response.data.data;
            const errorMessages = validationErrors.map((error) => {
              const field = error.path.split('.')[1];
              const errorMessage = field;
              return `${errorMessage}: ${error.msg}\n`;
            });
            const errorMessage = errorMessages.join('\n');
            toast.error(
              `Validation Error: \n${errorMessage}`,
              toastConfigErrorMsg
            );
            console.log('Toast Message', errorMessage);
          } else {
            toast.error('Something went wrong', toastConfig);
          }
        });
      console.log(data);
      console.log('Form data submitted successfully');
    } else {
      toast.info(
        <div>
          Hey There! - It’s Van 🙂.
          <br /> Just a quick note before you get started! <br />
          Our app provides results based on the information provided by you in
          this “About Me” section. <br /> <br />
          <strong>
            It is essential to double-check the accuracy of your information
            before relying on our services.
          </strong>
          <br /> <br />
          By using our app, you accept responsibility for the accuracy of the
          information provided by you in this section and any resulting
          outcomes. <br />
          We are not liable for inaccuracies due to user-provided information.
          Please verify your information looks correct from My Checklist and
          make sure you are happy with the results before proceeding.
          <br />
          Thank You 🙂
        </div>,
        toastConfigVerify
      );
    }
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 70;
      const elementPosition = element.offsetTop - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleInputChange = () => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };
  // const handleCurrentlyStudyingChange = (index, value) => {
  //   // Handle changes in the "Currently Studying" checkbox
  //   setValue(
  //     `education[${index}].end_date`,
  //     value ? undefined : watch(`education[${index}].end_date`)
  //   );
  // };

  const mappedCountries = countriesAll.map((country) => ({
    label: country.name,
    value: country.name
  }));

  return (
    <AppBarDrawer>
      {hasUnsavedChanges && <p>You have unsaved changes.</p>}
      <div className="section-box">
        <div className="d-flex justify-content-between">
          <h5 className="fw-bold">My Checklist</h5>
          <div>
            <svg
              id="clecklist-info"
              width="20"
              height="20"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12Zm11-1v6h-2v-6h2Zm-1 9c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm1-13v2h-2V7h2Z"
                clipRule="evenodd"
              ></path>
            </svg>
            <Tooltip
              anchorSelect="#clecklist-info"
              content="Make sure all the Information Provided on this Page is Correct and Tick each of the Section in the List below to Mark as Complete"
            />
          </div>
        </div>
        {sections.map(({ id, name }) => (
          <div key={id} className="d-flex justify-content-between">
            <button
              id={`${id}-btn`}
              className={`bg-transparent border-0 fw-semibold ${
                highlight[id] ? '' : 'text-primary'
              }`}
              onClick={() => scrollToSection(id)}
            >
              {name}
            </button>
            <input
              className="form-check-input"
              type="checkbox"
              checked={confirmations[id]}
              onChange={() => handleConfirmation(id)}
            />
          </div>
        ))}
      </div>
      <div className="container-fluid p-4 mb-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Other Information */}
          <div className="aboutme-section py-4 px-3 mb-2" id="other-info">
            <h3>Other Information</h3>
            <div className="row pt-2">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <label htmlFor="experience" className="form-label">
                    Total Experience (in years)
                  </label>
                  <Controller
                    name="years_of_experience"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          placeholder="5 Years"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.years_of_experience?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Veteran Status</p>
                  <Controller
                    name="veterans_status"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                          <option value="none">I do not wish to answer</option>
                        </select>
                        <p className="text-danger small">
                          {errors.veterans_status?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Disability</p>
                  <Controller
                    name="disability_status"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <p className="text-danger small">
                          {errors.disability_status?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Criminal Status</p>
                  <Controller
                    name="criminal_status"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <p className="text-danger small">
                          {errors.criminal_status?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Willing to relocate?</p>
                  <Controller
                    name="willing_to_relocate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <p className="text-danger small">
                          {errors.willing_to_relocate?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Race/Ethnicity</p>
                  <Controller
                    name="ethnicity"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="hispanic_or_latino">
                            Hispanic or Latino
                          </option>
                          <option value="american_indian_or_alaskan_native">
                            American Indian or Alaska Native
                          </option>
                          <option value="asian">Asian</option>
                          <option value="black_or_african_american">
                            Black or African American
                          </option>
                          <option value="native_hawaiian_or_other_pacific_islander">
                            Native Hawaiian or Other Pacific Islander
                          </option>
                          <option value="white">White</option>
                          <option value="two_or_more_races">
                            Two or more races
                          </option>
                          <option value="decline_to_self_identify">
                            Decline to self identify
                          </option>
                        </select>
                        <p className="text-danger small">
                          {errors.ethnicity?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <label htmlFor="expected_salary" className="form-label">
                    Expected Salary
                  </label>
                  <Controller
                    name="expected_salary.value"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          placeholder="Salary"
                          id="expected_salary"
                          type="number"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.expected_salary?.value?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Currency</p>
                  <Controller
                    name="expected_salary.currency"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Currency</option>
                          <option value="GBP">GBP</option>
                          <option value="USD">USD</option>
                          <option value="CAD">CAD</option>
                          <option value="NZD">NZD</option>
                          <option value="ZAR">ZAR</option>
                          <option value="AUD">AUD</option>
                          <option value="EUR">EUR</option>
                          <option value="BRL">BRL</option>
                        </select>
                        <p className="text-danger small">
                          {errors.expected_salary?.currency?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <label htmlFor="experience" className="form-label">
                    Current Salary
                  </label>
                  <Controller
                    name="current_salary.value"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          placeholder="Salary"
                          id="experience"
                          type="number"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.current_salary?.value?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Currency</p>
                  <Controller
                    name="current_salary.currency"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Currency</option>
                          <option value="GBP">GBP</option>
                          <option value="USD">USD</option>
                          <option value="CAD">CAD</option>
                          <option value="NZD">NZD</option>
                          <option value="ZAR">ZAR</option>
                          <option value="AUD">AUD</option>
                          <option value="EUR">EUR</option>
                          <option value="BRL">BRL</option>
                        </select>
                        <p className="text-danger small">
                          {errors.current_salary?.currency?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Driving License</p>
                  <Controller
                    name="has_driver_license"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <p className="text-danger small">
                          {errors.has_driver_license?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Do you have your own vehicle?</p>
                  <Controller
                    name="have_own_vehicle"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <p className="text-danger small">
                          {errors.have_own_vehicle?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Prefered Way of working</p>
                  <Controller
                    name="preferred_way_of_working"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="on-site">On-site</option>
                          <option value="remote">Remote</option>
                          <option value="hybrid">Hybrid</option>
                        </select>
                        <p className="text-danger small">
                          {errors.preferred_way_of_working?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <label htmlFor="experience" className="form-label">
                    Notice Periods (in Days)
                  </label>
                  <Controller
                    name="notice_period_in_days"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          placeholder="5 Days"
                          id="experience"
                          type="number"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.notice_period_in_days?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">Higher Education Level</p>
                  <Controller
                    name="highest_degree"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                        >
                          <option value="">Select Degree</option>
                          <option value="No formal education">
                            GED or Equilent
                          </option>
                          <option value="Primary education">High School</option>
                          <option value="Secondary education">
                            Some College
                          </option>
                          <option value="GED">GED</option>
                          <option value="Vocational qualification">
                            College - Associates
                          </option>
                          <option value="College - Bachelor of Arts">
                            College - Bachelor of Arts
                          </option>
                          <option value="College - Bachelor of fine Arts">
                            College - Bachelor of fine Arts
                          </option>
                          <option value="College - Bachelor of Science">
                            College - Bachelor of Science
                          </option>
                          <option value="College - Master of Arts">
                            College - Master of Arts
                          </option>
                          <option value="College - Master of Science">
                            College - Master of Science
                          </option>
                          <option value="College - Master of Fine Arts">
                            College - Master of Fine Arts
                          </option>
                          <option value="College - Master of Business Administration">
                            College - Master of Business Administration
                          </option>
                          <option value="Master's degree">
                            Master&apos;s degree
                          </option>
                          <option value="Doctorate or higher">
                            Doctorate or higher
                          </option>
                        </select>
                        <p className="text-danger small">
                          {errors.highest_degree?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">
                    Enter Dates when you are available for Interview
                  </p>
                  <Controller
                    name="interview_date"
                    control={control}
                    render={({ field }) => (
                      <>
                        <DatePicker
                          {...field}
                          multiple
                          value={dates}
                          dateSeparator="  |  "
                          minDate={today}
                          onChange={(e) => {
                            handleDateChange;
                            field.onChange(e);
                          }}
                          mapDays={({ date }) => {
                            let props = {};
                            let isWeekend = [0, 6].includes(date.weekDay.index);

                            if (isWeekend)
                              props.className = 'highlight highlight-red';

                            return props;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.interview_date?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="mb-3 aboutme-input multi-select">
                  <label htmlFor="authorisedCountries" className="form-label">
                    Countries you are authorized to work?
                  </label>
                  <Controller
                    name="autorized_work_country"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Select
                          {...field}
                          className="border-0 p-0"
                          options={mappedCountries}
                          placeholder={
                            loading
                              ? 'Loading Countries'
                              : 'e.g United States, United Kingdom, ...'
                          }
                          isMulti
                          isSearchable
                          onChange={(opt) => {
                            field.onChange(opt);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.autorized_work_country?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <label htmlFor="experience" className="form-label">
                    Companies to exclude from applying (comma separated)
                    (Optional)
                  </label>
                  <Controller
                    name="exclude_company"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          placeholder="Amazon, Google etc"
                          id="experience"
                          type="text"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.exclude_company?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">
                    Will you now, or in the future, require sponsorship for
                    employment visa status (e.g. H-1B visa status)?
                  </p>
                  <Controller
                    name="require_visa_sponsorship"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <p className="text-danger small">
                          {errors.require_visa_sponsorship?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <p className="mb-2">
                    Do you have an active security clearance? (Optional)
                  </p>
                  <Controller
                    name="has_security_clearance"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No / Not Applicable</option>
                        </select>
                        <p className="text-danger small">
                          {errors.has_security_clearance?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Personal Information */}
          <div className="aboutme-section py-4 px-3 mb-2" id="personal-info">
            <h3>Tell us a little bit about you..?</h3>
            <div className="row mt-4 justify-content-between">
              <div className="col-lg-5 col-md-12 col-12">
                <div className="mb-3 aboutme-input">
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          id="first_name"
                          placeholder="First Name"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.first_name?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 aboutme-input">
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          id="last_name"
                          placeholder="Last Name"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.last_name?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 aboutme-input">
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <>
                        <select
                          {...field}
                          className="py-1 px-2 w-100"
                          id="inputGroupSelect04"
                          aria-label="Example select with button addon"
                          name="gender"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="none">Prefer not to say</option>
                        </select>
                        <p className="text-danger small">
                          {errors.gender?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 aboutme-input">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          id="email"
                          type="email"
                          placeholder="Email"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.email?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 d-flex aboutme-input">
                  <div className="col-4 pe-1">
                    <Controller
                      name="country_code"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            className="form-control"
                            id="country_code"
                            type="tel"
                            placeholder="Country Code"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.country_code?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-8">
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            className="form-control"
                            id="phone_number"
                            type="tel"
                            placeholder="Phone Number"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.phone?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="mb-3 aboutme-input">
                  <Controller
                    name="address.street"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className="form-control"
                          type="address"
                          placeholder="Street Address"
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        />
                        <p className="text-danger small">
                          {errors.address?.street?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 d-flex aboutme-input">
                  <div className="col-6 pe-1">
                    <Controller
                      name="address.city"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                            placeholder="City"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.address?.city?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <Controller
                      name="address.state"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            className="form-control"
                            type="address"
                            placeholder="State"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.address?.state?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="mb-3 d-flex aboutme-input">
                  <div className="col-6 pe-1">
                    <Controller
                      name="address.postal_code"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                            placeholder="Zip/Postal Code"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.address?.postal_code?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <Controller
                      name="address.country"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                            placeholder="Country"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.address?.country?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-3 d-flex aboutme-input">
                  <img className="me-3" src={github} alt="GitHub" />
                  <Controller
                    name="github_url"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="d-block w-100">
                          <input
                            {...field}
                            className="form-control"
                            type="url"
                            placeholder="Enter GitHub Link"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.github_url?.message}
                          </p>
                        </div>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 d-flex aboutme-input">
                  <img className="me-3" src={linkedin} alt="LinkedIn" />
                  <Controller
                    name="linkedin_url"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="d-block w-100">
                          <input
                            {...field}
                            className="form-control"
                            type="url"
                            placeholder="Enter Linkedin Profile URL"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.linkedin_url?.message}
                          </p>
                        </div>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 d-flex aboutme-input">
                  <img className="me-3" src={behance} alt="Behance" />
                  <Controller
                    name="behance_url"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="d-block w-100">
                          <input
                            {...field}
                            className="form-control"
                            type="url"
                            placeholder="Enter Behance Profile URL"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.behance_url?.message}
                          </p>
                        </div>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 d-flex aboutme-input">
                  <img className="me-3" src={dribble} alt="Dribble" />
                  <Controller
                    name="dribbble_url"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="d-block w-100">
                          <input
                            {...field}
                            className="form-control"
                            type="url"
                            placeholder="Enter Dribble Profile URL"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.dribbble_url?.message}
                          </p>
                        </div>
                      </>
                    )}
                  />
                </div>
                <div className="mb-3 d-flex aboutme-input">
                  <img className="me-3" src={user} alt="User Portfolio" />
                  <Controller
                    name="portfolio_url"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="d-block w-100">
                          <input
                            {...field}
                            className="form-control"
                            type="url"
                            placeholder="Personal Website/Portfolio URL"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {errors.portfolio_url?.message}
                          </p>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3 aboutme-input">
                  <label htmlFor="resume_summary" className="form-label">
                    Resume Summary
                  </label>
                  <Controller
                    name="summary"
                    control={control}
                    render={({ field }) => (
                      <>
                        <textarea
                          {...field}
                          className="form-control"
                          rows="5"
                          placeholder="Resume Summary..."
                          onChange={(e) => {
                            field.onChange(e);
                            handleInputChange;
                          }}
                        ></textarea>
                        <p className="text-danger small">
                          {errors.summary?.message}
                        </p>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Work Experience */}
          <div className="aboutme-section py-4 px-3 mb-2" id="work-history">
            <div className="d-flex justify-content-between mb-3">
              <div className="p-0">
                <h3>Work Experience</h3>
                {errors.work && (
                  <p className="text-danger small">{errors.work?.message}</p>
                )}
              </div>
              <div className="text-end">
                <button
                  className="btn btn-primary px-5"
                  type="button"
                  onClick={addWork}
                >
                  Add New
                </button>
              </div>
            </div>
            {workFields.map((field, index) => (
              <div className="row border mx-1 p-2 mb-3" key={field.id}>
                <div className="col-lg-10 col-md-8 col-8">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`work.${index}.company_name`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Company Name</label>
                              <input
                                className="form-control"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.work?.[index]?.company_name?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`work.${index}.job_title`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Job Title</label>
                              <input
                                className="form-control"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.work?.[index]?.job_title?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`work.${index}.location`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Location</label>
                              <input
                                className="form-control"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.work?.[index]?.location?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`work.${index}.experience_type`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <p className="mb-2">Role Type</p>
                              <select
                                className="py-1 px-2 w-100"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              >
                                <option value="">Select Option</option>
                                <option value="Full Time">Full Time</option>
                                <option value="Part Time">Part Time</option>
                                <option value="Internship">Internship</option>
                                <option value="Contract">Contract</option>
                              </select>
                              <p className="text-danger small">
                                {errors.work?.[index]?.experience_type?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`work.${index}.start_date`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Start Date</label>
                              <input
                                className="form-control"
                                type="date"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.work?.[index]?.start_date?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`work.${index}.end_date`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                {isCurrentlyWorking ? 'Present' : 'End Date'}
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                {...field}
                                disabled={isCurrentlyWorking}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.work?.[index]?.end_date?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group mb-2">
                        <Controller
                          name={`work.${index}.currently_working`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor={`currentlyWorkHere-${index}`}
                                >
                                  Currently Work Here?
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`currentlyWorkHere-${index}`}
                                  {...field}
                                  checked={isCurrentlyWorking}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setIsCurrentlyWorking(!isCurrentlyWorking);
                                    handleInputChange;
                                  }}
                                />
                              </div>
                              <p className="text-danger small">
                                {
                                  errors.work?.[index]?.currently_working
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group mb-2">
                        <Controller
                          name={`work.${index}.description`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label
                                htmlFor="jobDescription"
                                className="form-label"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control"
                                rows="4"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              ></textarea>
                              <p className="text-danger small">
                                {errors.work?.[index]?.description?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-4 text-end pt-4">
                  <button
                    className="btn bg-transparent border-0"
                    type="button"
                    onClick={() => removeWorkItem(index)}
                  >
                    <img src={del} alt="Delete" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* Education */}
          <div className="aboutme-section py-4 px-3 mb-2" id="education">
            <div className="d-flex justify-content-between mb-3">
              <div className="p-0">
                <h3>Education</h3>
                {errors.education && (
                  <p className="text-danger small">
                    {errors.education?.message || 'Education is required'}
                  </p>
                )}
              </div>
              <div className="text-end">
                <button
                  className="btn btn-primary px-5"
                  type="button"
                  onClick={addEducation}
                >
                  Add New
                </button>
              </div>
            </div>
            {eduFields.map((field, index) => (
              <div className="row border mx-1 p-2 mb-3" key={field.id}>
                <div className="col-lg-10 col-md-8 col-8">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.degree_level`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Degree Level</label>
                              <select
                                className="form-control"
                                placeholder="Ex: Bachelor's of Software Engineering"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              >
                                <option value="">Select...</option>
                                <option value="None">None</option>
                                <option value="Secondary(10th Pass)">
                                  Secondary(10th Pass)
                                </option>
                                <option value="Higher Secondary(12th Pass)">
                                  Higher Secondary(12th Pass)
                                </option>
                                <option value="Diploma">Diploma</option>
                                <option value="Bachelor's">
                                  Bachelor`s / BSC
                                </option>
                                <option value="Master's">Master`s / MSC</option>
                                <option value="Doctorate">
                                  Doctorate / PHD
                                </option>
                              </select>
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.degree_level
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.degree_title`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Degree Title</label>
                              <input
                                className="form-control"
                                placeholder="Ex: Bachelor's of Software Engineering"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.degree_title
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.institution_name`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Institution Name
                              </label>
                              <input
                                className="form-control"
                                placeholder="Ex: Boston University"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.institution_name
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.institution_type`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Institution Type
                              </label>
                              <select
                                className="form-control"
                                placeholder="Ex: University"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              >
                                <option value="">
                                  Select Institution Type
                                </option>
                                <option value="University">University</option>
                                <option value="School">School</option>
                                <option value="College">College</option>
                              </select>
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.institution_type
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.institution_city`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Institution City
                              </label>
                              <input
                                className="form-control"
                                placeholder="Ex: London"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.institution_city
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.institution_country`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Institution Country
                              </label>
                              <input
                                className="form-control"
                                placeholder="Ex: United Kingdom"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.institution_country
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.field_of_study`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Field of Study
                              </label>
                              <input
                                className="form-control"
                                placeholder="Ex: Business"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.field_of_study
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.grade`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">GPA/Grade</label>
                              <input
                                className="form-control"
                                placeholder="GPA / Grade"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.education?.[index]?.grade?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.start_date`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Start Date</label>
                              <input
                                className="form-control"
                                type="date"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.education?.[index]?.start_date?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`education.${index}.end_date`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                {isCurrentlyStudying ? 'Present' : 'End Date'}
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                {...field}
                                disabled={isCurrentlyStudying}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.education?.[index]?.end_date?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group mb-2">
                        <Controller
                          name={`education.${index}.currently_studying`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor={`currentlyStudyingHere-${index}`}
                                >
                                  Currently Work Here?
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`currentlyStudyingHere-${index}`}
                                  {...field}
                                  checked={isCurrentlyStudying}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setIsCurrentlyStudying(
                                      !isCurrentlyStudying
                                    );
                                    handleInputChange;
                                  }}
                                />
                              </div>
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.currently_studying
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group mb-2">
                        <Controller
                          name={`education.${index}.description`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label
                                htmlFor="jobDescription"
                                className="form-label"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control"
                                rows="4"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              ></textarea>
                              <p className="text-danger small">
                                {
                                  errors.education?.[index]?.description
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-4 text-end pt-4">
                  <button
                    className="btn bg-transparent border-0"
                    type="button"
                    onClick={() => removeEducationItem(index)}
                  >
                    <img src={del} alt="Delete" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* Skills */}
          <div className="aboutme-section py-4 px-3 mb-2" id="skills">
            <div className="d-flex justify-content-between">
              <div className="p-0">
                <h3>Skills</h3>
                {errors.skills && (
                  <p className="text-danger small">
                    {errors.skills?.message || 'Skills are required'}
                  </p>
                )}
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-primary px-5 my-auto"
                  onClick={addSkill}
                >
                  Add New
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <div className="mb-3">
                <label htmlFor="applyExperience" className="form-label">
                  <span className="me-2">Update All Skills Experience</span>
                  <svg
                    id="UpdateAllSkillsExperience"
                    width="20"
                    height="20"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12Zm11-1v6h-2v-6h2Zm-1 9c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm1-13v2h-2V7h2Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <Tooltip
                    className="z-3"
                    anchorSelect="#UpdateAllSkillsExperience"
                    content="If you have lots of skills to update manually you can use this to bulk update the years of experience in skills"
                    place="right"
                  />
                </label>
                <div className="d-flex gap-2">
                  <input
                    type="number"
                    className="form-control w-auto"
                    id="applyExperience"
                    value={applyExperience}
                    onChange={(e) => setApplyExperience(e.target.value)}
                    placeholder="Years of Experience"
                  />
                  <button
                    type="button"
                    className="btn btn-primary my-auto"
                    onClick={handleApplyExperience}
                  >
                    Apply to All Skills
                  </button>
                </div>
                {/* <button
                  type="button"
                  className="btn btn-secondary mt-2 ms-2"
                  onClick={handleUndo}
                >
                  Undo
                </button> */}
              </div>
              {skillFields.map((field, index) => (
                <div className="col-lg-6 col-md-6 col-12 d-flex" key={field.id}>
                  <div className="mb-2 aboutme-input w-100">
                    <Controller
                      name={`skills.${index}.name`}
                      control={control}
                      render={({ field }) => (
                        <div className="d-block w-100">
                          <input
                            className="form-control me-1"
                            {...field}
                            placeholder="Skill Name"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small mb-0">
                            {errors.skills?.[index]?.name?.message}
                          </p>
                        </div>
                      )}
                    />
                  </div>
                  <div className="mb-2 aboutme-input w-100">
                    <Controller
                      name={`skills.${index}.years_of_experience`}
                      control={control}
                      render={({ field }) => (
                        <div className="d-block w-100">
                          <input
                            className="form-control ms-1"
                            type="number"
                            {...field}
                            placeholder="Years of Experience"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          />
                          <p className="text-danger small">
                            {
                              errors.skills?.[index]?.years_of_experience
                                ?.message
                            }
                          </p>
                        </div>
                      )}
                    />
                  </div>
                  <div className="pt-2 ps-2 w-25">
                    <button
                      className="skill-del-btn bg-transparent border-0"
                      type="button"
                      onClick={() => deleteSkill(index)}
                    >
                      <svg
                        width={25}
                        height={25}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                        <path d="m15 9-6 6" />
                        <path d="m9 9 6 6" />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Language */}
          <div className="aboutme-section py-4 px-3 mb-2" id="languages">
            <div className="d-flex justify-content-between">
              <div className="p-0">
                <h3>Languages</h3>
                {errors.languages && (
                  <p className="text-danger small">
                    {errors.languages?.message || 'Languages are required'}
                  </p>
                )}
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-primary px-5 my-auto"
                  onClick={addLanguage}
                >
                  Add New
                </button>
              </div>
            </div>
            <div className="row mt-3">
              {languageFields.map((field, index) => (
                <div className="col-lg-6 col-md-6 col-12 d-flex" key={field.id}>
                  <div className="mb-2 aboutme-input w-100">
                    <Controller
                      name={`languages.${index}.name`}
                      control={control}
                      render={({ field }) => (
                        <div className="d-block w-100">
                          <select
                            className="form-control me-1"
                            {...field}
                            placeholder="Language Name"
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          >
                            <option value="">Select a language</option>
                            {Object.entries(Languages).map(([code, name]) => (
                              <option key={code} value={name}>
                                {name}
                              </option>
                            ))}
                          </select>
                          <p className="text-danger small mb-0">
                            {errors.languages?.[index]?.name?.message}
                          </p>
                        </div>
                      )}
                    />
                  </div>
                  <div className="mb-2 aboutme-input w-100">
                    <Controller
                      name={`languages.${index}.level`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <select
                            className="px-2 w-100 ms-1"
                            aria-label="Example select with button addon"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange;
                            }}
                          >
                            <option value="">Proficiency Level</option>
                            <option value="beginner">Beginner</option>
                            <option value="intermediate">Intermediate</option>
                            <option value="advanced">Advanced</option>
                            <option value="Native">Native</option>
                          </select>
                          <p className="text-danger small">
                            {errors.languages?.[index]?.level?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                  <div className="pt-2 ps-2 w-25">
                    <button
                      className="skill-del-btn bg-transparent border-0"
                      type="button"
                      onClick={() => deleteLanguage(index)}
                    >
                      <svg
                        width={25}
                        height={25}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z" />
                        <path d="m15 9-6 6" />
                        <path d="m9 9 6 6" />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Achievements */}
          <div className="aboutme-section py-4 px-3 mb-2" id="achievements">
            <div className="d-flex justify-content-between mb-3">
              <div className="p-0">
                <h3>Achievements</h3>
                {errors.achievements && (
                  <p className="text-danger small">
                    {errors.achievements?.message}
                  </p>
                )}
              </div>
              <div className="text-end">
                <button
                  className="btn btn-primary px-5"
                  type="button"
                  onClick={addAchievement}
                >
                  Add New
                </button>
              </div>
            </div>
            {achievementFields.map((field, index) => (
              <div className="row border mx-1 p-2 mb-3" key={field.id}>
                <div className="col-lg-10 col-md-8 col-8">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`achievements.${index}.award_name`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Award Name</label>
                              <input
                                className="form-control"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.achievements?.[index]?.award_name
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`achievements.${index}.issued_by`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Issued By</label>
                              <input
                                className="form-control"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.achievements?.[index]?.issued_by
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`achievements.${index}.achievement_date`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Achievement Date
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.achievements?.[index]?.achievement_date
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group mb-2">
                        <Controller
                          name={`achievements.${index}.description`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label
                                htmlFor="jobDescription"
                                className="form-label"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control"
                                rows="4"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              ></textarea>
                              <p className="text-danger small">
                                {
                                  errors.achievements?.[index]?.description
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-4 text-end pt-4">
                  <button
                    className="btn bg-transparent border-0"
                    type="button"
                    onClick={() => removeAchievement(index)}
                  >
                    <img src={del} alt="Delete" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* Certifications */}
          <div className="aboutme-section py-4 px-3 mb-2" id="certifications">
            <div className="d-flex justify-content-between mb-3">
              <div className="p-0">
                <h3>Certifications</h3>
                {errors.certifications && (
                  <p className="text-danger small">
                    {errors.certifications?.message}
                  </p>
                )}
              </div>
              <div className="text-end">
                <button
                  className="btn btn-primary px-5"
                  type="button"
                  onClick={addCertificate}
                >
                  Add New
                </button>
              </div>
            </div>
            {certificationFields.map((field, index) => (
              <div className="row border mx-1 p-2 mb-3" key={field.id}>
                <div className="col-lg-10 col-md-8 col-8">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`certifications.${index}.name`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Certificate Name
                              </label>
                              <input
                                className="form-control"
                                placeholder="Certificate Name"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.certifications?.[index]?.name?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`certifications.${index}.issued_by`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Issued By</label>
                              <input
                                className="form-control"
                                placeholder="Conpany / Institute Name"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.certifications?.[index]?.issued_by
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`certifications.${index}.certification_date`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Certification Date
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.certifications?.[index]
                                    ?.certification_date?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`certifications.${index}.end_date`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">End Date</label>
                              <input
                                className="form-control"
                                type="date"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.certifications?.[index]?.end_date
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`certifications.${index}.link`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Certification Link (Optional)
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter link here"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {errors.certifications?.[index]?.link?.message}
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                      <div className="mb-3 aboutme-input">
                        <Controller
                          name={`certifications.${index}.certificate_id`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">
                                Certification ID (Optional)
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter Certificate ID"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              />
                              <p className="text-danger small">
                                {
                                  errors.certifications?.[index]?.certificate_id
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group mb-2">
                        <Controller
                          name={`certifications.${index}.description`}
                          control={control}
                          render={({ field }) => (
                            <div>
                              <label className="form-label">Description</label>
                              <textarea
                                className="form-control"
                                rows="4"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleInputChange;
                                }}
                              ></textarea>
                              <p className="text-danger small">
                                {
                                  errors.certifications?.[index]?.description
                                    ?.message
                                }
                              </p>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-4 text-end pt-4">
                  <button
                    className="btn bg-transparent border-0"
                    type="button"
                    onClick={() => deleteCertificate(index)}
                  >
                    <img src={del} alt="Delete" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* Cover Letter */}
          <div className="aboutme-section py-4 px-3 mb-2" id="cover-letter">
            <div className="cover-letter">
              <div className="d-flex justify-content-between">
                <div>
                  <h3 className="me-4">Cover Letter</h3>
                  {errors.cover_letter && (
                    <p className="text-danger small">
                      {errors.cover_letter?.message}
                    </p>
                  )}
                </div>
                <a
                  href="https://www.savetimeapply.com/free/mycoverletter"
                  target="_blank"
                  rel="noopener noreferrer"
                  // type="button"
                  // data-bs-target="#coverLetterModal"
                  // data-bs-toggle="modal"
                  className="help-genetate bg-transparent border-0"
                >
                  Generate With AI <img src={help_circle} alt="Help Circle" />
                </a>
              </div>
              {/* {coverLetterThreshold === 0 ? (
                <div>
                  <span className="text-danger">
                    Remaining Cover Letters: {coverLetterThreshold}
                  </span>
                  <span className="ps-3">
                    <Link
                      className="text-decoration-none fw-bold"
                      to={'/pricing-plan'}
                    >
                      Upgrade plan
                    </Link>{' '}
                    to get more cover letters
                  </span>
                </div>
              ) : (
                <p className="text-success">
                  Remaining Cover Letters: {coverLetterThreshold}
                </p>
              )} */}
              <div className="aboutme-input">
                <Controller
                  name={'cover_letter'}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <label className="form-label">Cover Letter</label>
                      <textarea
                        className="form-control"
                        placeholder="Start typing here"
                        rows="4"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          handleInputChange;
                        }}
                      ></textarea>
                      <p className="text-danger small">
                        {errors.cover_letter?.message}
                      </p>
                    </div>
                  )}
                />
                {/* <textarea
                  className="form-control w-100 mt-3"
                  name="Cover Letter"
                  placeholder="Write here"
                  id="coverletter"
                  rows="7"
                  value={coverletter}
                  onChange={(e) => {
                    setCoverLetter(e.target.value);
                  }}
                ></textarea> */}
              </div>
            </div>
            {/* Cover Letter Modal */}
            {/* <div
              className="modal fade ms-5 mt-5"
              id="coverLetterModal"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              aria-labelledby="staticBackdropLabel"
            >
              <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
                <div className="modal-content modal-aboutme">
                  <div className="modal-header">
                    <h5 className="modal-title" id="coverLetterModalLabel">
                      Generate Cover Letter
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3 aboutme-input">
                      <div className="form-group">
                        <label htmlFor="jobTitle" className="form-label">
                          Job Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="jobTitle"
                          name="jobTitle"
                          value={jobTitle}
                          onChange={(e) => setJobTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleGenerate}
                        disabled={isLoadingGenerate}
                      >
                        Generate
                      </button>
                    </div>
                    <div className="mb-3 aboutme-input">
                      <div className="form-group generate-coverletter">
                        <label className="form-label">
                          Generated Cover Letter
                        </label>
                        <textarea
                          className="form-control"
                          rows="6"
                          value={generatedCoverLetter}
                        ></textarea>
                        {isLoadingGenerate && (
                          <img
                            className="loading"
                            src={spinner}
                            alt="Loading..."
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    {isGenerated && (
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleSave}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Use This
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          {/* ---------------------- */}

          <button
            className="btn btn-primary update-resume-btn px-5"
            type="submit"
          >
            Update Resume
          </button>
        </form>
      </div>
    </AppBarDrawer>
  );
}
