import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBarDrawer from '../../components/layout/AppBarDrawer';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import sad_face from '../../images/sadAnimation.gif';
import jar from '../../helpers/cookies';
import { URL } from '../../config/URL';
import spinner from '../../images/Spinner.svg';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useUser } from '../../context/UserContext';
import Loading from '../../components/loader/Loading';
// import resume_holder from '../../images/resume-holder.png';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

function UpdateResume() {
  const {
    resumeList,
    loading,
    fetchResumeList,
    deleteResume,
    handleUploadResume,
    handleGetResumeData
  } = useUser();
  const [displayCvNames, setDisplayCvNames] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoadingResume, setIsLoadingResume] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const bearerToken = jar.get('token');
  const UpdateResumeAPI = `${URL}/resume`;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDisplayCvNames([...displayCvNames, file.name]);
    }
  };

  const handleDelete = async (_id) => {
    if (resumeList.length <= 1) {
      toast.error('Cannot delete the last resume', toastConfig);
      return;
    }
    try {
      const message = await deleteResume(_id);
      toast.success(message, toastConfig);
    } catch (error) {
      console.error(error);
      toast.error('File deletion failed!', toastConfig);
    }
  };

  const handleReset = () => {
    const fileInput = document.getElementById('uploadResume');

    if (fileInput) {
      fileInput.value = '';
    }
  };

  useEffect(() => {
    if (resumeList) {
      setFiles(resumeList);
    }
  }, [resumeList]);

  const uploadFile = async () => {
    const fileInput = document.getElementById('uploadResume');

    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      toast.info('File Uploading...', {
        position: 'top-right',
        autoClose: true,
        toastConfig
      });

      let _id;

      try {
        const response = await handleUploadResume(file, bearerToken);
        fetchResumeList();
        const key = response.data.key;
        // console.log('Resume Key Update', key);
        // console.log('Resume ID Update', _id);
        _id = response.data._id;

        setIsLoadingResume((prevState) => ({
          ...prevState,
          [_id]: true
        }));
        toast.success('File Uploaded Successfully', toastConfig);

        const ParseResumeData = await handleGetResumeData(key);
        const resumeData = {
          ...ParseResumeData,
          _id,
          key
        };

        navigate('/edit-resume', {
          state: { resumeData }
        });

        // console.log('ParseResumeData', ParseResumeData);

        // console.log('Redume Data : ', resumeData);

        handleReset();
      } catch (error) {
        // Handle error...
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.msg ===
            'You have reached the maximum number of resumes allowed for your current plan. Please upgrade your plan to add more resumes.'
        ) {
          setOpenModal(true);
          return;
        } else {
          toast.error(`Upload Error ${error.msg}`, toastConfig);
          handleReset();
          toast.error('File Upload failed', {
            position: 'top-right',
            autoClose: 3000,
            toastConfig
          });
        }
        setIsLoadingResume((prevState) => ({
          ...prevState,
          [_id]: false
        }));
      } finally {
        setIsLoadingResume((prevState) => ({
          ...prevState,
          [_id]: false
        }));
      }
    }
  };

  const handleEdit = (_id) => {
    setIsLoadingResume((prevState) => ({
      ...prevState,
      [_id]: true
    }));
    axios
      .get(`${UpdateResumeAPI}/${_id}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      })
      .then((response) => {
        navigate('/edit-resume', {
          state: { resumeData: response.data.data }
        });
      })
      .catch((error) => {
        console.error('Update Resume API error', error);
        toast.error('Server Error.', toastConfig);
      })
      .finally(() => {
        setIsLoadingResume((prevState) => ({
          ...prevState,
          [_id]: false
        }));
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return loading ? (
    <Loading />
  ) : (
    <AppBarDrawer>
      <div className="edit-resume-top-bar">
        <div className="my-auto">
          <p className="mb-0 p-0 h-text">Update Your Resume</p>
        </div>
        <div className="my-auto d-flex">
          <button
            className="btn upload-resume-btn btn-primary me-2 text-center pb-2"
            data-bs-toggle="modal"
            data-bs-target="#uploadresume"
            type="button"
          >
            <p className="my-auto">Upload Resume</p>
          </button>
        </div>
      </div>
      <div className="px-4">
        {files.length > 0 && (
          <div className="row mt-4">
            {files.map((file) => (
              <div
                className="col-lg-3 col-md-4 col-6 text-center mb-3"
                key={file.key}
                data-id={file._id}
              >
                <div className="resume-holder mx-auto">
                  {/* <img src={resume_holder} alt="Resume Holder" /> */}
                  <div className="resume-btns d-flex gap-2">
                    <button
                      className="btn delete-btn px-1 border-0"
                      onClick={() => handleDelete(file._id)}
                      disabled={isLoadingResume[file._id]}
                    >
                      <svg
                        width={25}
                        height={25}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={0.5}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M3 6h18" />
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                      </svg>
                    </button>
                    <button
                      className="btn edit-btn px-1 border-0"
                      onClick={() => handleEdit(file._id)}
                      disabled={isLoadingResume[file._id]}
                    >
                      <svg
                        width={25}
                        height={25}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={0.5}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                      </svg>
                    </button>
                  </div>
                  {isLoadingResume[file._id] && (
                    <img className="loading" src={spinner} alt="Loading..." />
                  )}
                </div>
                <p className="text-center pt-2 cv-name">{file.filename}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Modal Upload Resume */}
      <div
        className="modal fade "
        id="uploadresume"
        tabIndex="-1"
        aria-labelledby="cvNameLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content text-center py-4 px-3">
            <div className="modal-header">
              <h1 className="modal-title fs-5 mx-auto" id="cvNameLabel">
                Upload Resume
              </h1>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="input-group mb-3">
                  <input
                    type="file"
                    className="form-control r-input-boxshadow"
                    id="uploadResume"
                    accept=".pdf,.docx"
                    onChange={handleFileChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer text-center">
              <button
                type="button"
                className="btn btn-primary mx-auto px-5"
                data-bs-dismiss="modal"
                // onClick={handleReset}
                onClick={uploadFile}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <ReachedLimit open={openModal} onClose={handleCloseModal} />
    </AppBarDrawer>
  );
}

const ReachedLimit = ({ open, onClose, handleCloseModal }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '10px',
          p: 4
        }}
      >
        <div className="modal-content text-center py-4 px-3">
          <div className="modal-header py-0">
            <img
              className="mx-auto py-0 my-0"
              src={sad_face}
              alt="Sad Face Animation"
            />
          </div>
          <div className="modal-body px-5">
            <h6 className="px-4">
              Uh-oh - It seems you reached the limit. Upgrade now!
            </h6>
          </div>
          <div className="modal-footer text-center py-0">
            <Link
              to={'/pricing-plan'}
              type="button"
              className="btn btn-primary mx-auto px-5"
              onClick={handleCloseModal}
            >
              Upgrade Now
            </Link>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

ReachedLimit.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func
};

export default UpdateResume;
