import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import LoadingAPI from '../../components/loader/LoadingAPI';
import { useQuery, useMutation } from '@apollo/client';
import { JOBS_HISTORY_QUERY, JOB_QNA_PAGES_QUERY } from '../../GraphQL/queries';
import {
  JOB_FEEDBACK_MUTATION,
  JOB_QNA_FEEDBACK_MUTATION
} from '../../GraphQL/mutations';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const JobHistory = () => {
  const { resumeList } = useUser();
  const [selectedCV, setSelectedCV] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState();
  const [selectedJobId, setSelectedJobId] = useState();
  const [selectedJobTitle, setSelectedJobTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [reaction, setReaction] = useState('');
  const [review, setReview] = useState('');
  // const [openFeedbackForm, setOpenFeedbackForm] = useState(null); // Track which question's feedback form is open
  const [questionFeedback, setQuestionFeedback] = useState({}); // Store feedback for each question

  const [giveFeedbackToJob] = useMutation(JOB_FEEDBACK_MUTATION);
  const [giveFeedbackToJobQNA] = useMutation(JOB_QNA_FEEDBACK_MUTATION);

  const [page, setPage] = useState(1);
  const limit = 10;

  // Construct query variables conditionally
  const queryVariables = {
    page,
    limit
  };

  // Include search term if it's not empty
  if (searchTerm) {
    queryVariables.search = searchTerm;
  }

  // Include resume ID if it's not empty
  if (selectedCV) {
    queryVariables.resumeId = selectedCV;
  }

  // Include Status if it's not empty
  if (status) {
    queryVariables.statuses = status;
  }

  const { loading, error, data, refetch, fetchMore } = useQuery(
    JOBS_HISTORY_QUERY,
    {
      variables: {
        jobHistoryInput: queryVariables
      },
      notifyOnNetworkStatusChange: true
    }
  );

  const {
    loading: questionsLoading,
    error: questionsError,
    data: questionsData
  } = useQuery(JOB_QNA_PAGES_QUERY, {
    variables: { JobQNAPagesInput: { userJobId: selectedJobId } }
  });

  useEffect(() => {
    refetch();
  }, [selectedCV, status, searchTerm]);

  const handleOpen = (job) => {
    setSelectedJobId(job._id);
    setSelectedJobTitle(job.job.title);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log('Job History:', error ? error.message : 'Success');

  // Load more jobs
  const handleLoadMore = async () => {
    try {
      await fetchMore({
        variables: {
          jobHistoryInput: queryVariables
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            jobHistory: {
              ...fetchMoreResult.jobHistory,
              elements: [
                ...prev.jobHistory.elements,
                ...fetchMoreResult.jobHistory.elements
              ]
            }
          };
        }
      });
      setPage(page + 1); // Update the current page
    } catch (error) {
      console.error('Error loading more jobs:', error);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (data.jobHistory.elements.length < data.jobHistory.metadata.total) {
      setPage(page + 1);
    }
  };

  // console.log('Questions Error: ', questionsError);

  const handleJobFeedbackSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const feedbackInput = {
      userJobId: formData.get('userJobId'),
      reaction: formData.get('reaction'),
      review: formData.get('review')
    };

    if (!feedbackInput.reaction) {
      delete feedbackInput.reaction;
    }

    if (!feedbackInput.review) {
      delete feedbackInput.review;
    }

    try {
      await giveFeedbackToJob({
        variables: { GiveFeedbackToJobInput: feedbackInput }
      });
      console.log('Feedback submitted');
      toast.success('Feedback submitted', toastConfig);
      setReaction('');
      setReview('');
      setOpen(false);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('Error submitting feedback', toastConfig);
    }
  };

  // Question Feedback

  const handleQuestionFeedbackChange = (questionId, field, value) => {
    setQuestionFeedback((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        [field]: value
      }
    }));
  };

  console.log('Fields value', questionFeedback);

  const handleQuestionFeedbackSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const jobQNAId = formData.get('jobQNAId');
    const reaction = formData.get('reaction');
    const review = formData.get('review');

    const feedbackInput = {
      jobQNAId,
      reaction,
      review
    };

    if (!feedbackInput.reaction) {
      delete feedbackInput.reaction;
    }

    if (!feedbackInput.review) {
      delete feedbackInput.review;
    }

    console.log('Responseeee', reaction, review);

    try {
      await giveFeedbackToJobQNA({
        variables: { GiveFeedbackToJobQNAInput: feedbackInput }
      });
      console.log('Question feedback submitted');
      toast.success('Question feedback submitted', toastConfig);
      setQuestionFeedback((prev) => ({
        ...prev,
        [jobQNAId]: {
          reaction,
          review
        }
      }));
    } catch (error) {
      console.error('Error submitting question feedback:', error);
      toast.error('Error submitting question feedback', toastConfig);
    }
  };

  const questionReviewSuggestions = [
    'Great answer! Very helpful.',
    'The answer is good, but it could use more details.',
    'The answer needs improvement and more clarity.'
  ];

  // const reviewSuggestions = [
  //   'Great job! I found the answers are very helpful.',
  //   'The answer was okay, but it could be improved by adding more details.',
  //   'I didn`t find the answer useful. It needs more clarity and examples.'
  // ];

  return (
    <>
      {loading ? (
        <LoadingAPI />
      ) : (
        <>
          <div className="container-fluid px-0">
            <div className="row mb-0">
              <div className="col-4 mt-4 aboutme-input">
                <p className="apply-field-h mb-1">Select your CV/Resume</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="selectcv"
                  value={selectedCV}
                  onChange={(e) => setSelectedCV(e.target.value)}
                >
                  <option value="">Select CV/Resume</option>
                  {resumeList.map((resume) => (
                    <option key={resume.key} value={resume._id}>
                      {resume.filename}
                    </option>
                  ))}
                </select>
                <p className="small text-black-50 mb-0">
                  Check jobs for selected Resume
                </p>
              </div>
              <div className="col-4 mt-4 aboutme-input">
                <p className="apply-field-h mb-1">Select Status</p>
                <select
                  className="py-1 px-2 w-100"
                  id="inputGroupSelect04"
                  aria-label="Example select with button addon"
                  name="selectcv"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="APPLIED">APPLIED</option>
                  <option value="FAILED">FAILED</option>
                </select>
              </div>
              <div className="col-4 mt-4 aboutme-input">
                <p className="apply-field-h mb-1">Search</p>
                <input
                  className="py-1 px-2 w-100"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <hr />
            <div className="table-responsive bg-white rounded">
              <table className="table align-middle table-hover">
                <thead>
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Company</th>
                    <th scope="col">Job Status</th>
                    <th scope="col">Location</th>
                    <th scope="col">Job Source</th>
                    <th scope="col" className="text-end">
                      Job Link
                    </th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {data?.jobHistory?.elements?.map((job) => (
                    <tr key={job._id}>
                      <td
                        scope="row"
                        className="text-capitalize d-flex justify-content-between"
                      >
                        <span>{job.job.title.substring(0, 50)}</span>
                        {job.status === 'APPLIED' ? (
                          <button
                            className="btn bg-transparent text-primary py-0 px-1 border-0"
                            onClick={() => handleOpen(job)}
                          >
                            <svg
                              width="22"
                              height="22"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5ZM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5Zm-3-5c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3Z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="text-capitalize">{job.job.companyName}</td>
                      <td className="text-capitalize">{job.status}</td>
                      <td className="text-capitalize">{job.job.location}</td>
                      {/* <td className="text-capitalize">23 March 2024</td> */}
                      <td className="text-capitalize">{job.job.source}</td>
                      <td className="text-end">
                        <a
                          className="session-link"
                          href={job.job.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Job URL
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-4 mb-5 d-flex justify-content-between">
            <div className="d-flex gap-4">
              <p className="my-auto text-muted fw-semibold">
                Total Jobs: {data?.jobHistory?.metadata?.total}
              </p>
              <p className="my-auto text-muted fw-semibold">
                Current Page: {page}
              </p>
            </div>
            <div className="d-flex  gap-4">
              <p className="my-auto text-muted fw-semibold">
                Jobs per Page: {limit}
              </p>
              <button
                className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
                onClick={handlePreviousPage}
                disabled={page === 1}
              >
                Previous Page
              </button>
              <button
                className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
                onClick={handleNextPage}
              >
                Next Page
              </button>
              {/* {data.jobHistory.elements.length >= limit &&
        page < Math.ceil(data.jobHistory.metadata.total / limit) && (
          <button onClick={handleNextPage}>Next Page</button>
        )} */}
              {/* {data.jobHistory.elements.length < data.jobHistory.metadata.total && (
      )} */}
            </div>
          </div>
        </>
      )}
      {/* Modal Code */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 800,
            minWidth: 600,
            maxHeight: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '10px',
            overflowY: 'scroll',
            p: 4
          }}
        >
          <div className="modal-content px-2 border-0">
            <div className="modal-header py-2 d-flex justify-content-between">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Questions
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <h1 className="fw-semibold mb-0 pb-2 fs-5 border-bottom">
                Job Title - {selectedJobTitle}
              </h1>
              {questionsLoading ? (
                <p>Loading questions...</p>
              ) : questionsError ? (
                <p>Couldn`t fetch questions</p>
              ) : questionsData?.jobQNAPages?.length === 0 ? (
                <p>No any questions found</p>
              ) : (
                // <pre>{JSON.stringify(questionsData, null, 2)}</pre>
                questionsData?.jobQNAPages.map((page, pageIndex) => (
                  <div key={pageIndex}>
                    {page.QNAs.map((qna, qnaIndex) => (
                      <div key={qnaIndex} className="border-top pt-2">
                        <p className="fw-semibold mb-0">Q: {qna.question}</p>
                        <p className="text-break mb-0">
                          <span className="fw-semibold">A: </span>
                          <span className="text-secondary">{qna.answer}</span>
                        </p>
                        <div className="my-2">
                          <div className="d-flex gap-3 w-100 questionFeedback">
                            <p className="my-auto text-primary">
                              Is This Answer Correct?
                            </p>
                            <button
                              type="button"
                              className={`btn p-1 d-flex ${
                                questionFeedback[qna._id]?.reaction === 'LIKE'
                                  ? 'btn-primary'
                                  : 'btn-outline-primary'
                              }`}
                              onClick={() =>
                                handleQuestionFeedbackChange(
                                  qna._id,
                                  'reaction',
                                  'LIKE'
                                )
                              }
                            >
                              <svg
                                width="15"
                                height="15"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M21 8c1.1 0 2 .9 2 2l-.01.08.01.01V12c0 .26-.05.5-.14.73l-3.02 7.05C19.54 20.5 18.83 21 18 21H9c-1.1 0-2-.9-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.05c.27.27.44.65.44 1.06l-.03.32L14.69 8H21ZM5 21H1V9h4v12Z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </button>
                            <button
                              type="button"
                              className={`btn p-1 d-flex ${
                                questionFeedback[qna._id]?.reaction ===
                                'DISLIKE'
                                  ? 'btn-primary'
                                  : 'btn-outline-primary'
                              }`}
                              onClick={() =>
                                handleQuestionFeedbackChange(
                                  qna._id,
                                  'reaction',
                                  'DISLIKE'
                                )
                              }
                            >
                              <svg
                                width="15"
                                height="15"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 3h9c1.1 0 2 .9 2 2v10c0 .55-.22 1.05-.58 1.41L9.83 23l-1.06-1.05c-.27-.27-.44-.65-.44-1.06l.03-.32.95-4.57H3c-1.1 0-2-.9-2-2l.01-.08-.01-.01V12c0-.26.05-.5.14-.73l3.02-7.05C4.46 3.5 5.17 3 6 3Zm13 12V3h4v12h-4Z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </button>
                          </div>
                          {questionFeedback[qna._id]?.reaction && (
                            <form onSubmit={handleQuestionFeedbackSubmit}>
                              <input
                                type="hidden"
                                name="jobQNAId"
                                value={qna._id}
                              />
                              <input
                                type="hidden"
                                name="reaction"
                                value={
                                  questionFeedback[qna._id]?.reaction || ''
                                }
                              />
                              <div className="review-suggestions mt-2 d-grid text-start">
                                <p className="mb-0 fw-bold">
                                  Quick Suggestions
                                </p>
                                {questionReviewSuggestions.map(
                                  (suggestion, index) => (
                                    <div
                                      key={index}
                                      className="d-flex justify-content-between"
                                    >
                                      <label htmlFor={`suggestion${index}`}>
                                        {suggestion}
                                      </label>
                                      <input
                                        className="form-check-input feedback-checkbox"
                                        type="radio"
                                        name="suggestion"
                                        id={`suggestion${index}`}
                                        onClick={() =>
                                          handleQuestionFeedbackChange(
                                            qna._id,
                                            'review',
                                            suggestion
                                          )
                                        }
                                      />
                                      {/* <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm me-1 mt-1 text-start py-0"
                                        key={index}
                                       
                                      >
                                        {suggestion}
                                      </button> */}
                                    </div>
                                  )
                                )}
                              </div>
                              <textarea
                                className="form-control mt-2"
                                placeholder="How we can improved this answer next time?"
                                name="review"
                                value={questionFeedback[qna._id]?.review || ''}
                                onChange={(e) =>
                                  handleQuestionFeedbackChange(
                                    qna._id,
                                    'review',
                                    e.target.value
                                  )
                                }
                              />
                              <button
                                type="submit"
                                className="btn btn-primary mt-2"
                              >
                                Submit Feedback
                              </button>
                            </form>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              )}

              {/* {questionsLoading ? (
                <p>Loading questions...</p>
              ) : questionsData?.jobQNAPages.length === 0 ? (
                <p>No any questions found</p>
              ) : (
                questionsData?.jobQNAPages?.QNAs.map((qna, index) => (
                  <div key={index}>
                    <p className="fw-semibold mb-0">Q: {qna.question}</p>
                    <p>
                      <span className="fw-semibold">A: </span>
                      {qna.answer}
                    </p>
                  </div>
                ))
              )} */}
            </div>
            <hr />
            <div className="modal-footer text-center py-0 px-2">
              <form
                className="mx-auto w-100"
                onSubmit={handleJobFeedbackSubmit}
              >
                <input type="hidden" name="userJobId" value={selectedJobId} />
                <input type="hidden" name="reaction" value={reaction} />
                <div className="d-flex justify-content-between w-100">
                  <button
                    type="button"
                    className={`btn d-flex ${
                      reaction === 'LIKE'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    }`}
                    onClick={() => setReaction('LIKE')}
                  >
                    <p className="my-auto px-2 mb-0">Like</p>
                    <svg
                      width="25"
                      height="25"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M21 8c1.1 0 2 .9 2 2l-.01.08.01.01V12c0 .26-.05.5-.14.73l-3.02 7.05C19.54 20.5 18.83 21 18 21H9c-1.1 0-2-.9-2-2V9c0-.55.22-1.05.59-1.41L14.17 1l1.06 1.05c.27.27.44.65.44 1.06l-.03.32L14.69 8H21ZM5 21H1V9h4v12Z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    className={`btn d-flex ${
                      reaction === 'DISLIKE'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    }`}
                    onClick={() => setReaction('DISLIKE')}
                  >
                    <p className="my-auto px-2 mb-0">Dislike</p>
                    <svg
                      width="25"
                      height="25"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 3h9c1.1 0 2 .9 2 2v10c0 .55-.22 1.05-.58 1.41L9.83 23l-1.06-1.05c-.27-.27-.44-.65-.44-1.06l.03-.32.95-4.57H3c-1.1 0-2-.9-2-2l.01-.08-.01-.01V12c0-.26.05-.5.14-.73l3.02-7.05C4.46 3.5 5.17 3 6 3Zm13 12V3h4v12h-4Z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/* <div className="review-suggestions">
                  <p className="mb-0">Quick Suggestions</p>
                  {reviewSuggestions.map((suggestion, index) => (
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm me-1 mt-1"
                      key={index}
                      onClick={() => setReview(suggestion)}
                    >
                      {suggestion}
                    </button>
                  ))}
                </div> */}
                <textarea
                  className="form-control mt-2"
                  placeholder="Help us improved this next time..."
                  rows="3"
                  name="review"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
                <button
                  type="submit"
                  className="btn btn-primary mt-2"
                  disabled={!reaction || true}
                >
                  Submit Feedback
                </button>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default JobHistory;
