import { gql } from '@apollo/client';

export const SEARCH_JOBS_QUERY = gql`
  query SearchJobs($searchJobsInput: SearchJobsInput!) {
    searchJobs(input: $searchJobsInput) {
      _id
      title
      description
      descriptionHTML
      url
      companyName
      employmentType
      employmentHourType
      source
      location
      salary {
        currency
        minSalary
        maxSalary
        salaryType
      }
      estimatedFillTimeMs
      isExpired
      isArchived
    }
  }
`;

export const SAVED_JOBS_QUERY = gql`
  query SavedJobs($savedJobsInput: SavedJobsInput!) {
    savedJobs(input: $savedJobsInput) {
      elements {
        jobId
        job {
          _id
          title
          description
          descriptionHTML
          url
          companyName
          employmentType
          employmentHourType
          source
          location
          salary {
            currency
            minSalary
            maxSalary
            salaryType
          }
          estimatedFillTimeMs
          isExpired
          isArchived
        }
        _id
      }
      metadata {
        page
        limit
        total
      }
    }
  }
`;

export const QUEUED_JOBS_QUERY = gql`
  query QueuedJobs($queuedJobsInput: QueuedJobsInput!) {
    queuedJobs(input: $queuedJobsInput) {
      elements {
        jobId
        job {
          _id
          title
          description
          descriptionHTML
          url
          companyName
          employmentType
          employmentHourType
          source
          location
          salary {
            currency
            minSalary
            maxSalary
            salaryType
          }
          estimatedFillTimeMs
          isExpired
          isArchived
        }
        _id
        status
        resumeId
      }
      metadata {
        page
        limit
        total
      }
    }
  }
`;

export const JOBS_HISTORY_QUERY = gql`
  query JobHistory($jobHistoryInput: JobHistoryInput!) {
    jobHistory(input: $jobHistoryInput) {
      elements {
        jobId
        job {
          _id
          title
          description
          descriptionHTML
          url
          companyName
          employmentType
          employmentHourType
          source
          location
          salary {
            currency
            minSalary
            maxSalary
            salaryType
          }
          estimatedFillTimeMs
          isExpired
          isArchived
        }
        _id
        status
        resumeId
        failReason
      }
      metadata {
        page
        limit
        total
      }
    }
  }
`;

// get plans
export const GET_PLANS_QUERY = gql`
  query GetPlans {
    getPlans {
      _id
      price
      planDuration {
        value
        unit
      }
      features {
        name
        isIncluded
        isExclusive
      }
      name
      planType
      isRecommended
    }
  }
`;

// Get Current Plan
// export const GET_CURRENT_USER_PLAN = gql`
//   query GetCurrentUserPlan {
//     getCurrentUserPlan {
//       _id
//       availableTokens
//       planId {
//         name
//         price
//         rank
//         tokens
//       }
//     }
//   }
// `;

export const GET_CURRENT_USER_PLAN = gql`
  query GetCurrentUserPlan {
    getCurrentUserPlan {
      _id
      availableTokens
      isActive
      planId
      plan {
        _id
        name
        price
        tokens
        tokensType
        planType
        resumes
        tokenDuration {
          value
          unit
        }
        planDuration {
          value
          unit
        }
        features {
          name
          isIncluded
          isExclusive
        }
        isRecommended
      }
    }
  }
`;

// AutoApply Status
export const AUTO_APPLY_STATUS = gql`
  query IsAutoApplyPaused {
    isAutoApplyPaused
  }
`;

// QNA Response
export const JOB_QNA_PAGES_QUERY = gql`
  query JobQNAPages($JobQNAPagesInput: JobQNAPagesInput!) {
    jobQNAPages(input: $JobQNAPagesInput) {
      _id
      userId
      userJobId
      pageNumber
      QNAs {
        _id
        question
        type
        answer
        feedback {
          reaction
          review
        }
      }
    }
  }
`;
