import React, { useState } from 'react';
import LoadingAPI from '../../components/loader/LoadingAPI';
import jobCartIcon from '../../images/cart.svg';
import { useUser } from '../../context/UserContext';
import { Tooltip } from 'react-tooltip';
import { useQuery, useMutation } from '@apollo/client';
import { SAVED_JOBS_QUERY } from '../../GraphQL/queries';
import {
  QUEUE_JOB_MUTATION,
  REMOVE_SAVED_JOB_MUTATION
} from '../../GraphQL/mutations';
import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const JobCart = () => {
  const { resumeList } = useUser();
  const [selectedCV, setSelectedCV] = useState();
  const [page, setPage] = useState(1);
  const limit = 10; // Number of items per page
  const { loading, error, data, refetch, fetchMore } = useQuery(
    SAVED_JOBS_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        savedJobsInput: {
          page,
          limit
        }
      }
    }
  );

  console.log('Job Cart:', error ? error.message : 'Success');

  // const handleLoadMore = () => {
  //   setPage(page + 1);
  //   fetchMore();
  // };

  // HANDLE QUEUE JOBS
  const [queueJobMutation] = useMutation(QUEUE_JOB_MUTATION);
  const handleQueueJob = async (jobId) => {
    try {
      await queueJobMutation({
        variables: {
          queueJobsInput: {
            jobIds: jobId,
            resumeId: selectedCV
          }
        }
      });
      console.log('Job saved successfully!');
      toast.success('Job added to Queue successfully!', toastConfig);
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

  const [removeSavedJobsMutation] = useMutation(REMOVE_SAVED_JOB_MUTATION);
  const handleRemoveSavedJob = async (jobId) => {
    try {
      await removeSavedJobsMutation({
        variables: {
          removeSavedJobsInput: {
            savedJobIds: [jobId] // Modify this if you want to remove multiple jobs at once
          }
        }
      });
      console.log('Job removed successfully!');
      toast.success('Job removed successfully!', toastConfig);
      // Refetch saved jobs after removing
      await refetch();
    } catch (error) {
      console.error('Error removing job:', error);
    }
  };

  const handleLoadMore = async () => {
    try {
      await fetchMore({
        variables: {
          savedJobsInput: {
            page: page + 1, // Fetch the next page
            limit
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            savedJobs: {
              ...fetchMoreResult.savedJobs,
              elements: [
                ...prev.savedJobs.elements,
                ...fetchMoreResult.savedJobs.elements
              ]
            }
          };
        }
      });
      setPage(page + 1); // Update the current page
    } catch (error) {
      console.error('Error loading more jobs:', error);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (data.savedJobs.elements.length < data.savedJobs.metadata.total) {
      setPage(page + 1);
    }
  };

  // Add to Queue
  // const [button2Clicked, setButton2Clicked] = useState(false);

  // const handleClickButton2 = () => {
  //   setButton2Clicked(!button2Clicked);
  // };

  return loading ? (
    <LoadingAPI />
  ) : (
    <>
      <div>
        <div className="col-12 mt-4 aboutme-input">
          <p className="apply-field-h mb-1">
            Select CV/Resume from drop-down to apply jobs shown below
          </p>
          <select
            className="py-1 px-2 w-100"
            id="inputGroupSelect04"
            aria-label="Example select with button addon"
            name="selectcv"
            value={selectedCV}
            onChange={(e) => setSelectedCV(e.target.value)}
          >
            <option value="">Select CV/Resume</option>
            {resumeList.map((resume) => (
              <option key={resume.key} value={resume._id}>
                {resume.filename}
              </option>
            ))}
          </select>
          <p className="small text-black-50">
            Resume/CV selection is used to save your job for selected Resume
          </p>
        </div>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {data?.savedJobs?.elements?.map((savedJob) => (
          <div
            key={savedJob._id}
            className="matchme-box rounded p-4 border my-2"
          >
            <div className="d-flex gap-3 align-items-center">
              <div>
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 84 85"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M52.8739 2.72508L44.5864 0.5L37.6025 26.6166L31.2972 3.03836L23.0096 5.26344L29.822 30.738L12.8538 13.7361L6.78692 19.8151L25.3989 38.4644L2.22064 32.2413L0 40.5453L25.3251 47.3447C25.0352 46.0917 24.8818 44.786 24.8818 43.4444C24.8818 33.9484 32.5645 26.2503 42.0416 26.2503C51.5188 26.2503 59.2015 33.9484 59.2015 43.4444C59.2015 44.7773 59.05 46.075 58.7636 47.3207L81.7795 53.5L84 45.196L58.5742 38.3696L81.754 32.1461L79.5332 23.8421L54.1085 30.6681L71.0766 13.6663L65.0097 7.58727L46.6559 25.9778L52.8739 2.72508Z"
                    fill="#F97316"
                  />
                  <path
                    d="M59.1298 47.5C58.4424 50.438 56.9912 53.0774 55 55.192L71.1307 71.5L77 65.5663L59.1298 47.5Z"
                    fill="#F97316"
                  />
                  <path
                    d="M54.4782 55.5C52.4466 57.6137 49.8792 59.1911 47 60.004L52.9174 82.5L61 80.2937L54.4782 55.5Z"
                    fill="#F97316"
                  />
                  <path
                    d="M47 59.5783C45.6243 59.9321 44.1824 60.12 42.6965 60.12C41.1045 60.12 39.563 59.9041 38.0995 59.5L32 82.2692L40.3238 84.5L47 59.5783Z"
                    fill="#F97316"
                  />
                  <path
                    d="M37 59.1446C34.1227 58.2775 31.5635 56.6541 29.5438 54.5L13 71.375L19.0048 77.5L37 59.1446Z"
                    fill="#F97316"
                  />
                  <path
                    d="M30 55.0127C28.013 52.9359 26.5657 50.3606 25.8768 47.5L3 53.4843L5.20003 61.5L30 55.0127Z"
                    fill="#F97316"
                  />
                </svg>
              </div>
              <div className="w-100">
                <h4 className="fw-bold mb-0">{savedJob.job.title}</h4>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-3 align-items-center">
                    <p className="text-primary mt-1 mb-0">
                      You could <strong>save up to 10 minutes</strong> applying
                      to this job using SaveTimeApply
                      <span className="text-danger fs-4">*</span>
                    </p>
                  </div>
                  <div>
                    <p className="my-auto matchtime px-2 pb-1 mb-2">
                      30 minutes ago
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center my-2">
                  <ul className="d-flex gap-3 list-unstyled fw-semibold">
                    <li className="d-flex gap-2 align-items-center">
                      <svg
                        width="5"
                        height="4"
                        viewBox="0 0 5 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                          fill="black"
                        />
                      </svg>
                      Job Area
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <svg
                        width="5"
                        height="4"
                        viewBox="0 0 5 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                          fill="black"
                        />
                      </svg>
                      {savedJob.job.employmentHourType}
                    </li>
                    <li className="d-flex gap-2 align-items-center">
                      <svg
                        width="5"
                        height="4"
                        viewBox="0 0 5 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                          fill="black"
                        />
                      </svg>
                      {savedJob.job.employmentType}
                    </li>
                    {savedJob.job?.salary && (
                      <li className="d-flex gap-2 align-items-center">
                        <svg
                          width="5"
                          height="4"
                          viewBox="0 0 5 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                            fill="black"
                          />
                        </svg>
                        {`${savedJob.job?.salary?.currency} - ${savedJob.job?.salary?.minSalary} - ${savedJob.job?.salary?.maxSalary} / ${savedJob.job?.salary?.salaryType}`}
                      </li>
                    )}
                  </ul>
                  <div className="px-2 mb-2">{savedJob.job.location}</div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-3">
                    <span className="px-2 border">Analytics </span>
                    <span className="px-2 border">Management </span>
                    <span className="px-2 border">Skill Name </span>
                    <span className="px-2 border">Skill Name </span>
                    <span className="px-2 border">Skill Name </span>
                  </div>
                  <div className="d-flex gap-3">
                    <Tooltip
                      anchorSelect="#savejob"
                      content="Remove Job from JobCart"
                    />
                    <button
                      id="savejob"
                      className="my-auto btn-sm-jobcart-active"
                      type="button"
                      onClick={() => handleRemoveSavedJob(savedJob._id)}
                    >
                      <img src={jobCartIcon} alt="Job cart Icon" />
                    </button>
                    <Tooltip
                      anchorSelect="#queue"
                      content={
                        !selectedCV
                          ? 'Select CV first to queue job'
                          : 'Add to Queued Jobs'
                      }
                    />
                    <button
                      id="queue"
                      className="my-auto btn-sm-matchMe"
                      type="button"
                      onClick={() => handleQueueJob(savedJob.job._id)}
                      disabled={!selectedCV ? true : false}
                    >
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6Zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Zm0 14H8V4h12v12Zm-7-1h2v-4h4V9h-4V5h-2v4H9v2h4v4Z"></path>
                      </svg>
                    </button>
                    <button className="btn btn-outline-primary px-4">
                      Strong Match
                    </button>
                    <a
                      className="btn btn-primary px-5"
                      href={savedJob.job.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Apply Now
                    </a>
                    {/* <button className="btn btn-primary px-5">
                        Apply Now
                      </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="mt-4 mb-5 d-flex justify-content-between">
          <div className="d-flex gap-4">
            <p className="my-auto text-muted fw-semibold">
              Total Jobs: {data?.savedJobs?.metadata?.total}
            </p>
            <p className="my-auto text-muted fw-semibold">
              Current Page: {page}
            </p>
          </div>
          <div className="d-flex  gap-4">
            <p className="my-auto text-muted fw-semibold">
              Jobs per Page: {limit}
            </p>
            <button
              className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
              onClick={handlePreviousPage}
              disabled={page === 1}
            >
              Previous Page
            </button>
            <button
              className="btn btn-outline-primary px-4 py-1 mx-2 border-0"
              onClick={handleNextPage}
            >
              Next Page
            </button>
            {/* {data.savedJobs.elements.length >= limit &&
              page < Math.ceil(data.savedJobs.metadata.total / limit) && (
                <button onClick={handleNextPage}>Next Page</button>
              )} */}
            {/* {data.savedJobs.elements.length < data.savedJobs.metadata.total && (
            )} */}
          </div>
        </div>
      </div>
      {/* <div className="matchme-box rounded p-4 border my-2">
        <div className="d-flex gap-3 align-items-center">
          <div>
            <svg
              width="100"
              height="100"
              viewBox="0 0 84 85"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M52.8739 2.72508L44.5864 0.5L37.6025 26.6166L31.2972 3.03836L23.0096 5.26344L29.822 30.738L12.8538 13.7361L6.78692 19.8151L25.3989 38.4644L2.22064 32.2413L0 40.5453L25.3251 47.3447C25.0352 46.0917 24.8818 44.786 24.8818 43.4444C24.8818 33.9484 32.5645 26.2503 42.0416 26.2503C51.5188 26.2503 59.2015 33.9484 59.2015 43.4444C59.2015 44.7773 59.05 46.075 58.7636 47.3207L81.7795 53.5L84 45.196L58.5742 38.3696L81.754 32.1461L79.5332 23.8421L54.1085 30.6681L71.0766 13.6663L65.0097 7.58727L46.6559 25.9778L52.8739 2.72508Z"
                fill="#F97316"
              />
              <path
                d="M59.1298 47.5C58.4424 50.438 56.9912 53.0774 55 55.192L71.1307 71.5L77 65.5663L59.1298 47.5Z"
                fill="#F97316"
              />
              <path
                d="M54.4782 55.5C52.4466 57.6137 49.8792 59.1911 47 60.004L52.9174 82.5L61 80.2937L54.4782 55.5Z"
                fill="#F97316"
              />
              <path
                d="M47 59.5783C45.6243 59.9321 44.1824 60.12 42.6965 60.12C41.1045 60.12 39.563 59.9041 38.0995 59.5L32 82.2692L40.3238 84.5L47 59.5783Z"
                fill="#F97316"
              />
              <path
                d="M37 59.1446C34.1227 58.2775 31.5635 56.6541 29.5438 54.5L13 71.375L19.0048 77.5L37 59.1446Z"
                fill="#F97316"
              />
              <path
                d="M30 55.0127C28.013 52.9359 26.5657 50.3606 25.8768 47.5L3 53.4843L5.20003 61.5L30 55.0127Z"
                fill="#F97316"
              />
            </svg>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3 align-items-center">
                <h4 className="fw-bold">Lead Product Designer</h4>
                <p className="text-primary mt-1">
                  You could <strong>save up to 10 minutes</strong> applying to this
                  job using SaveTimeApply{' '}
                  <span className="text-danger fs-4">*</span>
                </p>
              </div>
              <div>
                <p className="my-auto matchtime px-2 pb-1 mb-2">
                  30 minutes ago
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center my-2">
              <ul className="d-flex gap-3 list-unstyled fw-semibold">
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Job Area
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Full Time
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Onsite
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  $124,000 - $150,000
                </li>
              </ul>
              <div className="px-2 mb-2">Canada</div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <span className="px-2 border">Analytics </span>
                <span className="px-2 border">Management </span>
                <span className="px-2 border">Skill Name </span>
                <span className="px-2 border">Skill Name </span>
                <span className="px-2 border">Skill Name </span>
              </div>
              <div className="d-flex gap-3">
                <button
                  className={`my-auto ${
                    button2Clicked ? 'btn-sm-matchMe-active' : 'btn-sm-matchMe'
                  }`}
                  type="button"
                  onClick={handleClickButton2}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.9974 18C8.87973 18 8.2355 17.5232 6.947 16.5695C0.182162 11.5624 0.845786 5.62745 3.77859 3.82846C6.0133 2.45769 7.96375 3.01009 9.1354 3.89001C9.6159 4.2508 9.85606 4.43119 9.9974 4.43119C10.1387 4.43119 10.3789 4.2508 10.8594 3.89001C12.0311 3.01009 13.9815 2.45769 16.2162 3.82846C17.6484 4.70698 18.5395 6.57186 18.2886 8.83333"
                      stroke={button2Clicked ? '#ffffff' : '#214289'}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M11.6641 15.5003C11.6641 15.5003 12.4974 15.5003 13.3307 17.167C13.3307 17.167 15.9778 13.0003 18.3307 12.167"
                      stroke={button2Clicked ? '#ffffff' : '#214289'}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button className="btn btn-outline-primary px-4">
                  Strong Match
                </button>
                <button className="btn btn-primary px-5">Apply Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="matchme-box rounded p-4 border my-2">
        <div className="d-flex gap-3 align-items-center">
          <div>
            <svg
              width="100"
              height="100"
              viewBox="0 0 84 85"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M52.8739 2.72508L44.5864 0.5L37.6025 26.6166L31.2972 3.03836L23.0096 5.26344L29.822 30.738L12.8538 13.7361L6.78692 19.8151L25.3989 38.4644L2.22064 32.2413L0 40.5453L25.3251 47.3447C25.0352 46.0917 24.8818 44.786 24.8818 43.4444C24.8818 33.9484 32.5645 26.2503 42.0416 26.2503C51.5188 26.2503 59.2015 33.9484 59.2015 43.4444C59.2015 44.7773 59.05 46.075 58.7636 47.3207L81.7795 53.5L84 45.196L58.5742 38.3696L81.754 32.1461L79.5332 23.8421L54.1085 30.6681L71.0766 13.6663L65.0097 7.58727L46.6559 25.9778L52.8739 2.72508Z"
                fill="#F97316"
              />
              <path
                d="M59.1298 47.5C58.4424 50.438 56.9912 53.0774 55 55.192L71.1307 71.5L77 65.5663L59.1298 47.5Z"
                fill="#F97316"
              />
              <path
                d="M54.4782 55.5C52.4466 57.6137 49.8792 59.1911 47 60.004L52.9174 82.5L61 80.2937L54.4782 55.5Z"
                fill="#F97316"
              />
              <path
                d="M47 59.5783C45.6243 59.9321 44.1824 60.12 42.6965 60.12C41.1045 60.12 39.563 59.9041 38.0995 59.5L32 82.2692L40.3238 84.5L47 59.5783Z"
                fill="#F97316"
              />
              <path
                d="M37 59.1446C34.1227 58.2775 31.5635 56.6541 29.5438 54.5L13 71.375L19.0048 77.5L37 59.1446Z"
                fill="#F97316"
              />
              <path
                d="M30 55.0127C28.013 52.9359 26.5657 50.3606 25.8768 47.5L3 53.4843L5.20003 61.5L30 55.0127Z"
                fill="#F97316"
              />
            </svg>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3 align-items-center">
                <h4 className="fw-bold">Lead Product Designer</h4>
                <p className="text-primary mt-1">
                  You could <strong>save up to 10 minutes</strong> applying to this
                  job using SaveTimeApply{' '}
                  <span className="text-danger fs-4">*</span>
                </p>
              </div>
              <div>
                <p className="my-auto matchtime px-2 pb-1 mb-2">
                  30 minutes ago
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center my-2">
              <ul className="d-flex gap-3 list-unstyled fw-semibold">
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Job Area
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Full Time
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Onsite
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  $124,000 - $150,000
                </li>
              </ul>
              <div className="px-2 mb-2">Canada</div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <span className="px-2 border">Analytics </span>
                <span className="px-2 border">Management </span>
                <span className="px-2 border">Skill Name </span>
                <span className="px-2 border">Skill Name </span>
                <span className="px-2 border">Skill Name </span>
              </div>
              <div className="d-flex gap-3">
                <button
                  className={`my-auto ${
                    button2Clicked ? 'btn-sm-matchMe-active' : 'btn-sm-matchMe'
                  }`}
                  type="button"
                  onClick={handleClickButton2}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.9974 18C8.87973 18 8.2355 17.5232 6.947 16.5695C0.182162 11.5624 0.845786 5.62745 3.77859 3.82846C6.0133 2.45769 7.96375 3.01009 9.1354 3.89001C9.6159 4.2508 9.85606 4.43119 9.9974 4.43119C10.1387 4.43119 10.3789 4.2508 10.8594 3.89001C12.0311 3.01009 13.9815 2.45769 16.2162 3.82846C17.6484 4.70698 18.5395 6.57186 18.2886 8.83333"
                      stroke={button2Clicked ? '#ffffff' : '#214289'}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M11.6641 15.5003C11.6641 15.5003 12.4974 15.5003 13.3307 17.167C13.3307 17.167 15.9778 13.0003 18.3307 12.167"
                      stroke={button2Clicked ? '#ffffff' : '#214289'}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button className="btn btn-outline-primary px-4">
                  Strong Match
                </button>
                <button className="btn btn-primary px-5">Apply Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="matchme-box rounded p-4 border my-2">
        <div className="d-flex gap-3 align-items-center">
          <div>
            <svg
              width="100"
              height="100"
              viewBox="0 0 84 85"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M52.8739 2.72508L44.5864 0.5L37.6025 26.6166L31.2972 3.03836L23.0096 5.26344L29.822 30.738L12.8538 13.7361L6.78692 19.8151L25.3989 38.4644L2.22064 32.2413L0 40.5453L25.3251 47.3447C25.0352 46.0917 24.8818 44.786 24.8818 43.4444C24.8818 33.9484 32.5645 26.2503 42.0416 26.2503C51.5188 26.2503 59.2015 33.9484 59.2015 43.4444C59.2015 44.7773 59.05 46.075 58.7636 47.3207L81.7795 53.5L84 45.196L58.5742 38.3696L81.754 32.1461L79.5332 23.8421L54.1085 30.6681L71.0766 13.6663L65.0097 7.58727L46.6559 25.9778L52.8739 2.72508Z"
                fill="#F97316"
              />
              <path
                d="M59.1298 47.5C58.4424 50.438 56.9912 53.0774 55 55.192L71.1307 71.5L77 65.5663L59.1298 47.5Z"
                fill="#F97316"
              />
              <path
                d="M54.4782 55.5C52.4466 57.6137 49.8792 59.1911 47 60.004L52.9174 82.5L61 80.2937L54.4782 55.5Z"
                fill="#F97316"
              />
              <path
                d="M47 59.5783C45.6243 59.9321 44.1824 60.12 42.6965 60.12C41.1045 60.12 39.563 59.9041 38.0995 59.5L32 82.2692L40.3238 84.5L47 59.5783Z"
                fill="#F97316"
              />
              <path
                d="M37 59.1446C34.1227 58.2775 31.5635 56.6541 29.5438 54.5L13 71.375L19.0048 77.5L37 59.1446Z"
                fill="#F97316"
              />
              <path
                d="M30 55.0127C28.013 52.9359 26.5657 50.3606 25.8768 47.5L3 53.4843L5.20003 61.5L30 55.0127Z"
                fill="#F97316"
              />
            </svg>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3 align-items-center">
                <h4 className="fw-bold">Lead Product Designer</h4>
                <p className="text-primary mt-1">
                  You could <strong>save up to 10 minutes</strong> applying to this
                  job using SaveTimeApply{' '}
                  <span className="text-danger fs-4">*</span>
                </p>
              </div>
              <div>
                <p className="my-auto matchtime px-2 pb-1 mb-2">
                  30 minutes ago
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center my-2">
              <ul className="d-flex gap-3 list-unstyled fw-semibold">
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Job Area
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Full Time
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  Onsite
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <svg
                    width="5"
                    height="4"
                    viewBox="0 0 5 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.48523 3.546C2.00923 3.546 1.60323 3.38267 1.26723 3.056C0.940568 2.72 0.777234 2.314 0.777234 1.838C0.777234 1.35267 0.940568 0.946666 1.26723 0.62C1.60323 0.284 2.00923 0.116 2.48523 0.116C2.97057 0.116 3.38123 0.284 3.71723 0.62C4.05323 0.946666 4.22123 1.35267 4.22123 1.838C4.22123 2.314 4.05323 2.72 3.71723 3.056C3.38123 3.38267 2.97057 3.546 2.48523 3.546Z"
                      fill="black"
                    />
                  </svg>
                  $124,000 - $150,000
                </li>
              </ul>
              <div className="px-2 mb-2">Canada</div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <span className="px-2 border">Analytics </span>
                <span className="px-2 border">Management </span>
                <span className="px-2 border">Skill Name </span>
                <span className="px-2 border">Skill Name </span>
                <span className="px-2 border">Skill Name </span>
              </div>
              <div className="d-flex gap-3">
                <button
                  className={`my-auto ${
                    button2Clicked ? 'btn-sm-matchMe-active' : 'btn-sm-matchMe'
                  }`}
                  type="button"
                  onClick={handleClickButton2}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.9974 18C8.87973 18 8.2355 17.5232 6.947 16.5695C0.182162 11.5624 0.845786 5.62745 3.77859 3.82846C6.0133 2.45769 7.96375 3.01009 9.1354 3.89001C9.6159 4.2508 9.85606 4.43119 9.9974 4.43119C10.1387 4.43119 10.3789 4.2508 10.8594 3.89001C12.0311 3.01009 13.9815 2.45769 16.2162 3.82846C17.6484 4.70698 18.5395 6.57186 18.2886 8.83333"
                      stroke={button2Clicked ? '#ffffff' : '#214289'}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M11.6641 15.5003C11.6641 15.5003 12.4974 15.5003 13.3307 17.167C13.3307 17.167 15.9778 13.0003 18.3307 12.167"
                      stroke={button2Clicked ? '#ffffff' : '#214289'}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button className="btn btn-outline-primary px-4">
                  Strong Match
                </button>
                <button className="btn btn-primary px-5">Apply Now</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default JobCart;
