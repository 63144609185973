import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../../images/SaveTimeApply.png';
import homeIcon from '../../images/homeIcon.png';
import homeActive from '../../images/homeIconWhite.png';
import editResume from '../../images/editResumeIcon.png';
import editResumeActive from '../../images/editResumeIconWhite.png';
import trackJob from '../../images/trackYourJobIcon.png';
import trackJobActive from '../../images/trackYourJobIconWhite.png';
import matchmeActive from '../../images/matchMeActive.png';
import matchme from '../../images/matchMe.png';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { removeCookies } from '../../helpers/cookies';
import { useUser } from '../../context/UserContext';
import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

export default function AppBarDrawer(props) {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const [open, setOpen] = React.useState(
    sessionStorage.getItem('drawerOpen') === 'true'
  );
  const { userProfile } = useUser();
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
    sessionStorage.setItem('drawerOpen', 'true');
  };

  const handleDrawerClose = () => {
    setOpen(false);
    sessionStorage.setItem('drawerOpen', 'false');
  };

  let activeStyle = {
    background: '#214289',
    color: 'white',
    textDecoration: 'none',
    borderRadius: '8px'
  };

  let nonActiveStyle = {
    textDecoration: 'none',
    color: '#3b3838',
    borderRadius: '8px'
  };

  // logout
  const handleLogout = () => {
    removeCookies();
    navigate('/login');
    toast.success(
      'You have successfully logged out. See you soon!',
      toastConfig
    );
  };

  // Real Time Greetings
  const [greeting, setGreeting] = useState('');
  const currentDate = new Date();

  const getGreeting = () => {
    const hour = currentDate.getHours();

    if (hour >= 0 && hour < 12) {
      setGreeting('Good Morning');
    } else if (hour >= 12 && hour < 16) {
      setGreeting('Good Afternoon');
    } else if (hour >= 16 && hour < 20) {
      setGreeting('Good Evening');
    } else {
      setGreeting('Good Night');
    }
  };

  useEffect(() => {
    const drawerState = sessionStorage.getItem('drawerOpen') === 'true';
    setOpen(drawerState);
    getGreeting();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: 'white' }}>
        <Toolbar>
          <IconButton
            color="white"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <ListItemText
            primary={`${greeting}, ${userProfile?.first_name || ''} ${
              userProfile?.last_name || ''
            }`}
            secondary={
              <span>
                <span>Welcome to</span>
                <span className="fw-bold"> SaveTimeApply!</span>
              </span>
            }
            sx={{ color: 'black' }}
          />
          <Box sx={{ textAlign: 'right' }}>
            <span className="mr-2 d-flex my-auto">
              <Link
                to={'/pricing-plan'}
                className="text-decoration-none btn-primary me-4 py-1 my-auto px-4"
              >
                Upgrade Plan
              </Link>
              <Box sx={{ flexGrow: 0, background: 'transparent' }}>
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={() => setOpenUserMenu(!openUserMenu)}
                    sx={{ p: 0 }}
                  >
                    <Avatar
                      className="me-3"
                      alt={userProfile?.first_name}
                      src={userProfile?.picture}
                    />
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  // anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(openUserMenu)}
                  onClose={() => setOpenUserMenu(false)}
                  PaperProps={{
                    style: {
                      width: 200
                    }
                  }}
                >
                  <MenuItem
                    key={'review'}
                    onClick={() => {
                      setOpenUserMenu(!openUserMenu);
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 12 }}
                      textAlign="center"
                      color="black"
                    >
                      {userProfile?.email}
                    </Typography>
                  </MenuItem>
                  <MenuItem key={'logout'} onClick={handleLogout}>
                    <Typography textAlign="center" color="rgba(214, 54, 54, 1)">
                      Log Out
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </span>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="align-middle ps-3 py-2 justify-content-between">
          <NavLink to="/dashboard">
            <img
              className="ms-1"
              alt="icon"
              width={124}
              height={47}
              src={logo}
            />
          </NavLink>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List className="pt-3">
          <ListItem>
            <NavLink
              className="px-2 w-100"
              to={'/dashboard'}
              style={({ isActive }) =>
                isActive ? activeStyle : nonActiveStyle
              }
            >
              <div className="align-middle px-3 py-2 d-flex">
                {pathname !== '/dashboard' ? (
                  <img src={homeIcon} width={24} height={28} alt="home" />
                ) : (
                  <img src={homeActive} width={24} height={28} alt="home" />
                )}
                <p className="my-auto ms-3">Home</p>
              </div>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              className="px-2 w-100"
              to="/update-resume"
              style={({ isActive }) =>
                isActive ? activeStyle : nonActiveStyle
              }
            >
              <div className="align-middle px-3 py-2 d-flex">
                {pathname !== '/update-resume' ? (
                  <img src={editResume} width={24} height={28} alt="home" />
                ) : (
                  <img
                    src={editResumeActive}
                    width={24}
                    height={28}
                    alt="home"
                  />
                )}
                <p className="my-auto ms-3">About Me</p>
              </div>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              className="px-2 w-100"
              to="/match"
              style={({ isActive }) =>
                isActive ? activeStyle : nonActiveStyle
              }
            >
              <span className="align-middle px-3 py-2 d-flex">
                {pathname !== '/match' ? (
                  <img src={matchme} width={24} height={28} alt="Match Me" />
                ) : (
                  <img
                    src={matchmeActive}
                    width={24}
                    height={28}
                    alt="Match Me"
                  />
                )}
                <p className="my-auto ms-3">Match Me</p>
              </span>
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              className="px-2 w-100"
              to="/track-job"
              style={({ isActive }) =>
                isActive ? activeStyle : nonActiveStyle
              }
            >
              <div className="align-middle px-3 py-2 d-flex">
                {pathname !== '/track-job' ? (
                  <img src={trackJob} width={24} height={28} alt="track job" />
                ) : (
                  <img
                    src={trackJobActive}
                    width={24}
                    height={28}
                    alt="track job"
                  />
                )}
                <p className="my-auto ms-3">Track Jobs</p>
              </div>
            </NavLink>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  );
}
