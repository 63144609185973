import { gql } from '@apollo/client';

export const SAVE_JOBS_MUTATION = gql`
  mutation Savejobs($saveJobsInput: SaveJobsInput!) {
    saveJobs(input: $saveJobsInput)
  }
`;

export const REMOVE_SAVED_JOB_MUTATION = gql`
  mutation RemoveSavedJobs($removeSavedJobsInput: RemoveSavedJobsInput!) {
    removeSavedJobs(input: $removeSavedJobsInput)
  }
`;

export const QUEUE_JOB_MUTATION = gql`
  mutation QueueJobs($queueJobsInput: QueueJobsInput!) {
    queueJobs(input: $queueJobsInput)
  }
`;

export const RMOVE_QUEUED_JOB_MUTATION = gql`
  mutation RemoveQueuedJobs($removeQueuedJobsInput: RemoveQueuedJobsInput!) {
    removeQueuedJobs(input: $removeQueuedJobsInput)
  }
`;

// export const PURCHASE_PLAN_MUTATION = gql`
//   mutation PurchasePlan($purchasePlanInput: PurchasePlanInput!) {
//     purchasePlan(input: $purchasePlanInput)
//   }
// `;

export const PURCHASE_PLAN_MUTATION = gql`
  mutation PurchasePlan($email: String!, $planId: String!) {
    purchasePlan(input: { email: $email, planId: $planId })
  }
`;

export const ENABLE_AUTOAPPLY_MUTATION = gql`
  mutation ChangeAutoApplyStatus(
    $changeAutoApplyStatusInput: ChangeAutoApplyStatusInput!
  ) {
    changeAutoApplyStatus(input: $changeAutoApplyStatusInput)
  }
`;

export const JOB_FEEDBACK_MUTATION = gql`
  mutation GiveFeedbackToJob($GiveFeedbackToJobInput: GiveFeedbackToJobInput!) {
    giveFeedbackToJob(input: $GiveFeedbackToJobInput)
  }
`;

export const JOB_QNA_FEEDBACK_MUTATION = gql`
  mutation GiveFeedbackToJobQNA(
    $GiveFeedbackToJobQNAInput: GiveFeedbackToJobQNAInput!
  ) {
    giveFeedbackToJobQNA(input: $GiveFeedbackToJobQNAInput)
  }
`;

export const CANCEL_CURRENT_PLAN_MUTATION = gql`
  mutation CancelCurrentPlan {
    cancelCurrentPlan
  }
`;
