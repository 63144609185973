import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_PLAN, AUTO_APPLY_STATUS } from './queries';

export const useCurrentPlan = () => {
  const { loading, error, data } = useQuery(GET_CURRENT_USER_PLAN);

  if (loading) return { loading };
  if (error) return { error };

  return { data: data.getCurrentUserPlan };
};

export const AutoApplyStatus = () => {
  const { loading, error, data, refetch } = useQuery(AUTO_APPLY_STATUS);

  if (loading) return { loading };
  if (error) return { error };

  return { data: data.isAutoApplyPaused, refetch };
};
